import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Login from "@/views/login/Login.vue";
import Home from "@/views/home/Home.vue";
import Menu from "@/views/menu/Menu.vue";
import Perfil from "@/views/perfil/Perfil.vue";
import ConsultaSolicitacoes from "@/views/solicitacao-projeto/consulta-solicitacao/ConsultaSolicitacoes.vue";
import GerenciarPedidosAlinhadores from "@/views/pedidos-alinhadores-gerenciar/GerenciarPedidosAlinhadores.vue";
import ListaProjetosAptosPedido from "@/views/pedidos-alinhadores-novo/ListaProjetosAptosPedido.vue";
import NovoPedidoAlinhadores from "@/views/pedidos-alinhadores-novo/NovoPedidoAlinhadores.vue";
import NovaSolicitacaoProjeto from "@/views/solicitacao-projeto/nova-solicitacao/NovaSolicitacaoProjeto.vue";
import ImagemPerfil from "@/views/perfil/image-perfil/ImagemPerfil.vue";
import TabsNavigation from "@/views/TabsNavigation.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/main/home",
  },
  {
    path: "/main/",
    component: TabsNavigation,
    children: [
      {
        path: "",
        redirect: "/main/home",
      },
      {
        path: "/main/menu",
        name: "Menu",
        component: Menu,
        meta: {
          page: 2,
          navBarDisable: false,
        },
      },
      {
        path: "/main/home",
        name: "Home",
        component: Home,
        meta: {
          page: 3,
          navBarDisable: false,
        },
      },
      {
        path: "/main/perfil",
        name: "Perfil",
        component: Perfil,
        meta: {
          page: 4,
          navBarDisable: false,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      page: 1,
      navBarDisable: true,
    },
  },
  {
    path: "/gerenciar-pedidos-alinhadores",
    name: "GerenciarPedidosAlinhadores",
    component: GerenciarPedidosAlinhadores,
    meta: {
      page: 5,
      navBarDisable: false,
    },
  },
  {
    path: "/solicitacao-projeto/consulta-solicitacoes",
    name: "ConsultaSolicitacaoProjeto",
    component: ConsultaSolicitacoes,
    meta: {
      page: 6,
      navBarDisable: true,
    },
  },
  {
    path: "/lista-projetos-aptos-pedido",
    name: "ListaProjetosAptosPedido",
    component: ListaProjetosAptosPedido,
    meta: {
      page: 7,
      navBarDisable: true,
    },
  },
  {
    path: "/novo-pedido-alinhador/:nrProjeto",
    name: "NovoPedidoAlinhadores",
    component: NovoPedidoAlinhadores,
    meta: {
      page: 8,
      navBarDisable: true,
    },
    props: true,
  },
  {
    path: "/solicitacao-projeto/nova-solicitacao-projeto",
    name: "NovaSolicitacaoProjeto",
    component: NovaSolicitacaoProjeto,
    meta: {
      page: 8,
      navBarDisable: true,
    },
    props: true,
  },
  {
    path: "/perfil/imagem",
    name: "ImagemPerfil",
    component: ImagemPerfil,
    meta: {
      page: 9,
      navBarDisable: false,
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

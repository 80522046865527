
import { defineComponent, ref } from "vue";

import {
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  modalController,
  loadingController,
} from "@ionic/vue";

import { lockOpen } from "ionicons/icons";

import { UserNewPasswordRequest } from "@/repository/user/UserInterfaces";
import UserRepository from "@/repository/user/UserRepository";
import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";

const menu = defineComponent({
  setup() {
    const isRedefinedPassword = ref<boolean>(false);
    const setIsRedefinedPassword = (state: boolean) =>
      (isRedefinedPassword.value = state);

    const emailLostPassword = ref<string>("");

    const openModal = async (title: string, message: string, color: string) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: "danger",
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const lostPassword = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      console.log("email", emailLostPassword.value);
      try {
        loading.present();
        const payload: UserNewPasswordRequest = {
          email: emailLostPassword.value,
        };

        await UserRepository.lostPassword(payload);
        loading.dismiss();
        setIsRedefinedPassword(true);
      } catch (error) {
        loading.dismiss();
        openModal(
          "Erro ao efetuar login",
          `${error.response.data["hydra:description"]}`,
          "danger"
        );
      }
    };

    return {
      emailLostPassword,
      lostPassword,
      lockOpen,
      isRedefinedPassword,
    };
  },
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
  },
});

export default menu;

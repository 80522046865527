
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  menuController,
  onIonViewWillEnter,
} from "@ionic/vue";
import { home, menu, personCircleOutline } from "ionicons/icons";

import { ref } from "@vue/reactivity";

import { onBeforeMount } from "@vue/runtime-core";
import { getUser } from "@/service/storage/SessionStorageService";

export default {
  name: "TabsNavigation",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
  },
  setup() {
    const userShortName = ref("");

    const transFormUserName = async () => {
      const user = await getUser();
      console.log(user);
      const shortName = user.nome.split(" ")[0];
      userShortName.value = shortName;
    };

    const openMenu = () => {
      menuController.enable(true, "main");
      menuController.open("main");
    };

    onIonViewWillEnter(() => {
      transFormUserName();
    });

    return {
      home,
      menu,
      personCircleOutline,
      openMenu,
      userShortName,
    };
  },
};

<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="tertiary">
        <ion-buttons slot="end" @click="navigate('ListaProjetosAptosPedido')">
          <ion-icon size="large" :icon="closeOutline"></ion-icon>
        </ion-buttons>
        <ion-title>
          Novo Pedido Alinhadores
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item-group v-if="!loading && projeto">
        <ion-item>
          <ion-label>
            <p>
              Nr. Projeto
            </p>
            <h2>
              {{ projeto.nrProjeto }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>
              Paciente
            </p>
            <h2>
              {{ projeto.solicitacaoProjeto.paciente.nome }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>Alinhadores Superiores</ion-label>
        </ion-item-divider>
        <ion-item lines="none">
          <ion-label>
            <p>
              Disponíveis
            </p>
            <h2>
              {{
                projeto.qtdTotalAlinhadoresSuperior -
                  projeto.qtdAlinhadoresSuperiorSolicitados
              }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Incluídos
            </p>
            <h2>
              {{ listaAlSuperioresIncluidos.length }}
            </h2>
          </ion-label>
          <ion-label>
            <ion-button
              size="small"
              shape="round"
              expand="block"
              color="tertiary"
              strong
              :disabled="
                projeto.qtdTotalAlinhadoresSuperior -
                  projeto.qtdAlinhadoresSuperiorSolicitados <
                  1
              "
              @click="
                setListaAlinhadoresSelected(projeto.listaAlinhadoresSuperior);
                setTipoListaSelected('superior');
                setOpenModalPedidoAlinhadoresRef(true);
              "
            >
              Selecionar
            </ion-button>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Guia Attachment Superior</ion-label>
          <ion-checkbox
            :disabled="
              projeto.guiaAttach === null ||
                projeto.guiaAttach.attachmentSuperior.pedido === true ||
                projeto.guiaAttach.attachmentSuperior.disponivel === false ||
                listaAlSuperioresIncluidos.length === 0
            "
            color="primary"
            :checked="alinhadorAttachmentSuperiorNovoPedido"
            @update:modelValue="
              setAlinhadorAttachmentSuperiorNovoPedido($event)
            "
            :modelValue="alinhadorAttachmentSuperiorNovoPedido"
            slot="start"
          ></ion-checkbox>
        </ion-item>
        <ion-item-divider>
          <ion-label>Alinhadores Inferiores</ion-label>
        </ion-item-divider>
        <ion-item lines="none">
          <ion-label>
            <p>
              Disponíveis
            </p>
            <h2>
              {{
                projeto.qtdTotalAlinhadoresInferior -
                  projeto.qtdAlinhadoresInferiorSolicitados
              }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Incluídos
            </p>
            <h2>
              {{ listaAlInferioresIncluidos.length }}
            </h2>
          </ion-label>
          <ion-label>
            <ion-button
              size="small"
              shape="round"
              expand="block"
              color="tertiary"
              strong
              :disabled="
                projeto.qtdTotalAlinhadoresInferior -
                  projeto.qtdAlinhadoresInferiorSolicitados <
                  1
              "
              @click="
                setListaAlinhadoresSelected(projeto.listaAlinhadoresInferior);
                setTipoListaSelected('inferior');
                setOpenModalPedidoAlinhadoresRef(true);
              "
            >
              Selecionar
            </ion-button>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Guia Attachment Inferior</ion-label>
          <ion-checkbox
            :disabled="
              projeto.guiaAttach === null ||
                projeto.guiaAttach.attachmentInferior.pedido === true ||
                projeto.guiaAttach.attachmentInferior.disponivel === false ||
                listaAlInferioresIncluidos.length === 0
            "
            color="primary"
            :checked="alinhadorAttachmentInferiorNovoPedido"
            @update:modelValue="
              setAlinhadorAttachmentInferiorNovoPedido($event)
            "
            :modelValue="alinhadorAttachmentInferiorNovoPedido"
            slot="start"
          ></ion-checkbox>
        </ion-item>
        <ion-item-divider>
          <ion-label>Endereço Entrega</ion-label>
        </ion-item-divider>
        <ion-item-group v-if="!loadingEndereco">
          <ion-item>
            <ion-label>
              <p>
                Logradouro / Número
              </p>
              <h2>
                {{ enderecoEntrega.logradouro + ", " + enderecoEntrega.numero }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>
                Complemento
              </p>
              <h2>
                {{
                  enderecoEntrega.complemento
                    ? enderecoEntrega.complemento
                    : "Não Informado"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>
                Bairro
              </p>
              <h2>
                {{ enderecoEntrega.bairro }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>
                Município
              </p>
              <h2>
                {{ enderecoEntrega.municipio }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>
                UF
              </p>
              <h2>
                {{ enderecoEntrega.uf }}
              </h2>
            </ion-label>
            <ion-label>
              <p>
                CEP
              </p>
              <h2>
                {{ enderecoEntrega.cep }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-col size="6">
                <ion-button
                  expand="block"
                  color="warning"
                  size="small"
                  shape="round"
                  strong
                  @click="setIsOpenModalListaEnderecosRef(true)"
                >
                  Alterar Endereço
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button
                  @click="continuarPedido"
                  expand="block"
                  color="tertiary"
                  size="small"
                  shape="round"
                  strong
                >
                  Continuar Pedido
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item-group>
        <ion-item-group v-if="loadingEndereco">
          <ion-item v-for="n in 5" :key="n">
            <ion-label>
              <ion-skeleton-text animated style="width: 80%; height: 2rem" />
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-item-group>
      <ion-item-group v-if="loading">
        <ion-item v-for="n in 10" :key="n">
          <ion-label>
            <ion-skeleton-text animated style="width: 80%; height: 2rem" />
          </ion-label>
        </ion-item>
      </ion-item-group>
      <ion-modal
        :is-open="isOpenModalPedidoAlinhadoresRef"
        @onDidDismiss="setOpenModalPedidoAlinhadoresRef(false)"
      >
        <BluealModal
          title="Seleção Alinhadores"
          color="primary"
          :closeModal="setOpenModalPedidoAlinhadoresRef"
        >
          <template v-slot:content>
            <SelecaoAlinhadores
              :listaAlinhadores="listaAlinhadoresSelected"
              :tipoLista="tipoListaSelected"
              @atualizarLista="
                atualizarLista($event);
                setOpenModalPedidoAlinhadoresRef(false);
              "
            />
          </template>
        </BluealModal>
      </ion-modal>
      <ion-modal
        :is-open="isOpenModalConfirmacaoPedidoRef"
        @onDidDismiss="setIsOpenModalConfirmacaoPedidoRef(false)"
      >
        <BluealModal
          title="Confirmação Pedido"
          color="primary"
          :closeModal="setIsOpenModalConfirmacaoPedidoRef"
        >
          <template v-slot:content>
            <ConfirmacaoPedido
              :alinhadorAttachmentInferior="
                projeto.guiaAttach &&
                projeto.guiaAttach.attachmentInferior.pedido === true
                  ? false
                  : alinhadorAttachmentInferiorNovoPedido
              "
              :alinhadorAttachmentSuperior="
                projeto.guiaAttach &&
                projeto.guiaAttach.attachmentSuperior.pedido === true
                  ? false
                  : alinhadorAttachmentSuperiorNovoPedido
              "
              :enderecoEntrega="enderecoEntrega"
              :projeto="projeto"
              :listaAlSuperiores="listaAlSuperioresIncluidos"
              :listaAlInferiores="listaAlInferioresIncluidos"
            />
          </template>
        </BluealModal>
      </ion-modal>
      <ion-modal
        :is-open="isOpenModalListaEnderecosRef"
        @onDidDismiss="setIsOpenModalListaEnderecosRef(false)"
      >
        <BluealModal
          title="Lista Endereço"
          color="primary"
          :closeModal="setIsOpenModalListaEnderecosRef"
        >
          <template v-slot:content>
            <ListaEnderecosModal
              @enderecoSelecionado="atualizarEnderecoEntrega($event)"
            />
          </template>
        </BluealModal>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonModal,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonItemDivider,
  IonItemGroup,
  IonButton,
  IonCheckbox,
  IonButtons,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  alertController,
  loadingController,
  onIonViewWillEnter,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, onBeforeMount, ref, watch } from "vue";
import { useRouter } from "vue-router";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";
import SelecaoAlinhadores from "@/views/pedidos-alinhadores-novo/add-ons/SelecaoAlinhadores.vue";
import ConfirmacaoPedido from "@/views/pedidos-alinhadores-novo/add-ons/ConfirmacaoPedido.vue";
import ListaEnderecosModal from "@/views/enderecos/ListaEnderecosModal.vue";
import enderecoRespositoryV2 from "@/repository/enderecoV2Repository";
import cepRepository from "@/repository/terceiros/cepRepository";
import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonModal,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonItemDivider,
    IonItemGroup,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    BluealModal,
    SelecaoAlinhadores,
    ConfirmacaoPedido,
    ListaEnderecosModal,
  },
  props: {
    nrProjeto: String,
  },
  setup(props) {
    const isOpenModalPedidoAlinhadoresRef = ref(false);
    const setOpenModalPedidoAlinhadoresRef = (state) =>
      (isOpenModalPedidoAlinhadoresRef.value = state);

    const isOpenModalConfirmacaoPedidoRef = ref(false);
    const setIsOpenModalConfirmacaoPedidoRef = (state) =>
      (isOpenModalConfirmacaoPedidoRef.value = state);

    const isOpenModalListaEnderecosRef = ref(false);
    const setIsOpenModalListaEnderecosRef = (state) =>
      (isOpenModalListaEnderecosRef.value = state);

    const listaAlSuperioresIncluidos = ref([]);
    const setListaAlSuperioresIncluidos = (state) =>
      (listaAlSuperioresIncluidos.value = state);

    const listaAlInferioresIncluidos = ref([]);
    const setListaAlInferioresIncluidos = (state) =>
      (listaAlInferioresIncluidos.value = state);

    const projeto = ref();
    const setProjeto = (state) => (projeto.value = state);

    const enderecoEntrega = ref();
    const setEnderecoEntrega = (state) => (enderecoEntrega.value = state);

    const loadingEndereco = ref(true);
    const setLoadingEndereco = (state) => (loadingEndereco.value = state);

    const loading = ref(true);
    const setLoading = (state) => (loading.value = state);

    const alinhadorAttachmentInferiorNovoPedido = ref(false);
    const setAlinhadorAttachmentInferiorNovoPedido = (state) => {
      alinhadorAttachmentInferiorNovoPedido.value = state;
    };

    const alinhadorAttachmentSuperiorNovoPedido = ref(false);
    const setAlinhadorAttachmentSuperiorNovoPedido = (state) => {
      alinhadorAttachmentSuperiorNovoPedido.value = state;
    };

    const listaAlinhadoresSelected = ref([]);
    const setListaAlinhadoresSelected = (state) =>
      (listaAlinhadoresSelected.value = state);

    const tipoListaSelected = ref("");
    const setTipoListaSelected = (state) => (tipoListaSelected.value = state);

    const cepInvalid = ref(false);
    const setCepInvalid = (state) => (cepInvalid.value = state);

    const navigation = useRouter();

    const navigate = (routeName) => {
      navigation.push({ name: routeName });
    };

    const openModalError = async (title, message, color) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const validarCep = async (cep) => {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      if (!loading.value) {
        loadingIonic.present();
      }
      cep = cep.match(/[0-9]+/g).join("");

      try {
        const response = await cepRepository.buscaCep(cep);
        if (
          typeof response.data.erro === "undefined" ||
          response.data.erro === false
        ) {
          setCepInvalid(false);
        } else {
          setCepInvalid(true);
        }
        loadingIonic.dismiss();
      } catch (e) {
        loadingIonic.dismiss();
        setCepInvalid(true);
      }
    };

    const buscarProjeto = async () => {
      setLoading(true);
      try {
        const response = await projetoRepositoryV2.buscaProjeto(
          props.nrProjeto
        );
        setProjeto(response.data);
        setAlinhadorAttachmentInferiorNovoPedido(
          projeto.value.guiaAttach &&
            projeto.value.guiaAttach.attachmentInferior.pedido
            ? true
            : false
        );
        setAlinhadorAttachmentSuperiorNovoPedido(
          projeto.value.guiaAttach &&
            projeto.value.guiaAttach.attachmentSuperior.pedido
            ? true
            : false
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        openModalError(
          "Erro",
          "Erro ao buscar dados do planejamento",
          "danger"
        );
      }
    };

    const buscarEndereco = async () => {
      setLoadingEndereco(true);
      try {
        const params = {
          estadoEndereco: 10,
          tipoEndereco: 10,
        };
        const response = await enderecoRespositoryV2.buscaEnderecosAtivosUsuario(
          params
        );
        if (response.data["hydra:member"].length > 0) {
          setEnderecoEntrega(response.data["hydra:member"][0]);
          validarCep(enderecoEntrega.value.cep);
        } else {
          setEnderecoEntrega(null);
          setCepInvalid(true);
        }
        setLoadingEndereco(false);
      } catch (e) {
        setLoadingEndereco(false);
        openModalError("Erro", "Erro ao buscar endereço", "danger");
      }
    };

    const atualizarLista = (payload) => {
      if (payload.lista === "superior") {
        setListaAlSuperioresIncluidos(payload.listaSelecao);
      } else if (payload.lista === "inferior") {
        setListaAlInferioresIncluidos(payload.listaSelecao);
      }
    };

    const atualizarEnderecoEntrega = (endereco) => {
      setIsOpenModalListaEnderecosRef(false);
      setEnderecoEntrega(endereco);
      validarCep(enderecoEntrega.value.cep);
    };

    const continuarPedido = async () => {
      if (
        listaAlSuperioresIncluidos.value.length === 0 &&
        listaAlInferioresIncluidos.value.length === 0
      ) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione ao menos 1 (um) alinhador",
          buttons: ["Fechar"],
        });
        await alert.present();
      } else if (cepInvalid.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "O cep do endereço de entrega é inválido",
          buttons: ["Fechar"],
        });
        await alert.present();
      } else {
        setIsOpenModalConfirmacaoPedidoRef(true);
      }
    };

    onBeforeMount(() => {
      buscarProjeto();
      buscarEndereco();
    });

    onIonViewWillEnter(() => {
      setListaAlSuperioresIncluidos([]);
      setListaAlInferioresIncluidos([]);
    });

    watch(
      () => props.nrProjeto,
      () => {
        buscarProjeto();
        buscarEndereco();
      }
    );

    return {
      isOpenModalPedidoAlinhadoresRef,
      setOpenModalPedidoAlinhadoresRef,
      isOpenModalConfirmacaoPedidoRef,
      isOpenModalListaEnderecosRef,
      setIsOpenModalListaEnderecosRef,
      setIsOpenModalConfirmacaoPedidoRef,
      setEnderecoEntrega,
      setListaAlinhadoresSelected,
      setTipoListaSelected,
      atualizarEnderecoEntrega,
      navigate,
      tipoListaSelected,
      projeto,
      loading,
      listaAlSuperioresIncluidos,
      listaAlinhadoresSelected,
      listaAlInferioresIncluidos,
      alinhadorAttachmentInferiorNovoPedido,
      setAlinhadorAttachmentInferiorNovoPedido,
      alinhadorAttachmentSuperiorNovoPedido,
      setAlinhadorAttachmentSuperiorNovoPedido,
      loadingEndereco,
      enderecoEntrega,
      atualizarLista,
      continuarPedido,
      closeOutline,
    };
  },
});
</script>

<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Informações Paciente
      </ion-label>
    </ion-item-divider>
    <ion-item-group>
      <ion-item>
        <ion-label position="floating"><h3>Objetivo Alinhadores</h3></ion-label>
        <ion-textarea
          v-model="objetivoRef"
          required
          :minlength="3"
          @ionChange="emitirDadosTratamento()"
          placeholder="O que pretende fazer..."
        ></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label position="floating"><h3>Queixa Principal</h3></ion-label>
        <ion-textarea
          v-model="queixaRef"
          required
          :minlength="3"
          @ionChange="emitirDadosTratamento()"
          placeholder="Descreva..."
        ></ion-textarea>
      </ion-item>
      <ion-item lines="none">
        <ion-label><h3>Possui implantes</h3></ion-label>
        <ion-toggle
          @ionChange="setPossuiImplantesRef(!possuiImplantesRef)"
          :value="possuiImplantesRef"
          :checked="possuiImplantesRef"
          color="primary"
        />
      </ion-item>
      <ion-item v-if="possuiImplantesRef" lines="none">
        <ion-label position="floating"><h3>Descreva</h3></ion-label>
        <ion-textarea
          v-model="implantesRef"
          :minlength="3"
          @ionChange="emitirDadosTratamento()"
          :required="possuiImplantesRef"
        ></ion-textarea>
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonTextarea,
  IonToggle,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosTratamentoPaciente",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonTextarea,
    IonToggle,
  },
  emits: ["dadosTratamentoPacienteAlteracao"],
  setup(props, { emit }) {
    const objetivoRef = ref<string | null>(null);
    const queixaRef = ref<string | null>(null);
    const implantesRef = ref<string | null>(null);
    const possuiImplantesRef = ref<boolean>(false);
    const setPossuiImplantesRef = (state: boolean) =>
      (possuiImplantesRef.value = state);

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        queixaPaciente: queixaRef.value,
        objetivoAlinhadores: objetivoRef.value,
        implantesDescricao: implantesRef.value,
      };

      emit("dadosTratamentoPacienteAlteracao", dadosTratametno);
    };

    return {
      objetivoRef,
      queixaRef,
      implantesRef,
      possuiImplantesRef,
      setPossuiImplantesRef,
      emitirDadosTratamento,
    };
  },
});
</script>

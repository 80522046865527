import Repository from "@/repository/Repository";

const resource = '/solicitacao_projeto';

export default {
    buscarSolicitacao(id) {
        return Repository.get(
            `${resource}/${id}`
        );
    },
    listarSolicitacoes(page) {
        return Repository.get(
            `${resource}`,
            {
                params: {
                    page: page
                }
            }
        );
    },
    listarSolicitacoesFiltro(estado, page) {
        return Repository.get(
            `${resource}`,
            {
                params: {
                    estado: estado,
                    page: page
                }
            }
        );
    },
    gerarSolicitacao(body) {
        return Repository.post(
            `${resource}`,
            body
        );
    },
    alterarSolicitacao(id, body) {
        return Repository.put(
            `${resource}/${id}`,
            body
        );
    }
}


import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import store from "@/storage/storage";

const home = defineComponent({
  name: "Home",
  setup() {
    const router = useRouter();
    //const store = Storage.getKey;

    onBeforeMount(async () => {
      const user = await store.get("@user");
      console.log("USER", user);
    });

    const navigation = (routeName: string) => {
      router.push({ name: routeName });
    };

    return { navigation };
  },
  components: {
    IonContent,
    IonPage,
  },
});

export default home;

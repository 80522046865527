<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end" @click="navigate('Home')">
          <ion-icon size="large" :icon="closeOutline"></ion-icon>
        </ion-buttons>
        <ion-title>
          Solicitação de Planejamento
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="stepRef === 1" class="ion-padding">
      <form @submit.prevent="gerarSolicitacaoProjeto()">
        <DadosPaciente @pacienteAlterado="setPacienteRef($event)" />
        <DadosTratamentoPaciente
          @dadosTratamentoPacienteAlteracao="
            setDadosTratamentoPacienteRef($event)
          "
        />
        <DadosTratamento
          @dadosTratamentoAlteracao="setDadosTratamentoRef($event)"
        />
        <DadosAdicionaisTratamento
          @dadosAdicionaisTratamentoAlteracao="
            setDadosAdicionaisTratamentoRef($event)
          "
        />
        <ion-grid style="margin-top: 1.5rem">
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button type="submit" strong shape="round">
                <ion-icon
                  slot="end"
                  :icon="arrowForwardCircleOutline"
                ></ion-icon>
                Continuar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
    <ion-content v-if="stepRef === 2" class="ion-padding">
      <ImagensPaciente
        @atualizarArquivoImagens="setArquivoImagensRef($event)"
        @atualizarTipoEnvioImagens="setTipoEnvioImagensRef($event)"
      />
      <ion-grid style="margin-top: 1.5rem">
        <ion-row class="ion-justify-content-center">
          <ion-col size="6">
            <ion-button
              @click="tratarImagensPacientes"
              color="light"
              type="submit"
              strong
              shape="round"
            >
              <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
              Continuar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-content v-if="stepRef === 3" class="ion-padding">
      <form @submit.prevent="tratarEnvioModelos()">
        <ArquivosSolicitacao
          @atualizarArquivosModelos="setArquivosModelosRef($event)"
          @atualizarDadosAcessoRadiologia="setDadosAcessoRadioRef($event)"
          @atualizarTipoEnvioModelos="setTipoEnvioModelosRef($event)"
          @atualizarArquivoMaxila="setArquivoMaxilaRef($event)"
          @atualizarArquivoMandibula="setArquivoMandibulaRef($event)"
        />
        <ion-grid style="margin-top: 1.5rem">
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button color="light" type="submit" strong shape="round">
                <ion-icon
                  slot="end"
                  :icon="arrowForwardCircleOutline"
                ></ion-icon>
                Continuar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
    <ion-content v-if="stepRef === 4" class="ion-padding">
      <DadosCobranca
        @atualizarQtdDiasVencimento="setQtdDiasVencimentoRef($event)"
      />
      <ion-grid style="margin-top: 1.5rem">
        <ion-row class="ion-justify-content-center">
          <ion-col size="6">
            <ion-button
              @click="tratarCobranca()"
              color="light"
              type="submit"
              strong
              shape="round"
            >
              <ion-icon slot="end" :icon="checkmarkCircleOutline"></ion-icon>
              Finalizar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-content v-if="stepRef === 5" class="ion-padding">
      <ion-item-group>
        <ion-item lines="none">
          <ion-label>
            <h2>Solicitação finalizada com sucesso!</h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <h2>Nr. {{ dadosSolicitacaoRef.codigoSolicitacao }}</h2>
          </ion-label>
        </ion-item>
      </ion-item-group>
      <ion-item-group v-if="dadosSolicitacaoRef.isentoCobranca === 'N'">
        <ion-item lines="none">
          <ion-label>
            <p>Cobrança Gerada!</p>
          </ion-label>
          <ion-button @click="recuperarBoleto" slot="end" strong shape="round">
            <ion-icon slot="start" :icon="barcodeOutline"></ion-icon>
            Acesse o boleto
          </ion-button>
        </ion-item>
      </ion-item-group>
      <ion-item-group v-if="dadosSolicitacaoRef.isentoCobranca === 'S'">
        <ion-item lines="none">
          <ion-label>
            <p>Não foi gerado cobrança</p>
          </ion-label>
        </ion-item>
      </ion-item-group>
      <ion-grid style="margin-top: 1.5rem">
        <ion-row class="ion-justify-content-center">
          <ion-col size="6">
            <ion-button
              @click="navigate('Home')"
              color="light"
              type="submit"
              strong
              shape="round"
            >
              <ion-icon slot="end" :icon="checkmarkCircleOutline"></ion-icon>
              Fechar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  onIonViewWillEnter,
  modalController,
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonItemGroup,
  IonLabel,
  alertController,
  loadingController,
} from "@ionic/vue";

import {
  closeOutline,
  arrowForwardCircleOutline,
  checkmarkCircleOutline,
  barcodeOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";
import DadosPaciente from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/DadosPaciente.vue";
import DadosTratamentoPaciente from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/DadosTratamentoPaciente.vue";
import DadosTratamento from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/DadosTratamento.vue";
import DadosAdicionaisTratamento from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/DadosAdiconaisTratamento.vue";
import ImagensPaciente from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/ImagensPaciente.vue";
import ArquivosSolicitacao from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/ArquivosSolicitacao.vue";
import DadosCobranca from "@/views/solicitacao-projeto/nova-solicitacao/add-ons/DadosCobranca.vue";
import { listarSolicitacoesFiltro } from "@/repository/SolicitacaoProjeto/SolicitacaoProjetoListaRepositoryV2";
import {
  gerarSolicitacao,
  alterarSolicitacao,
} from "@/repository/SolicitacaoProjeto/SolicitacaoProjetoRepositoryV2";
import { gerarLinkArquivos } from "@/repository/SolicitacaoProjeto/solicitacaoProjetoArquivosRepositoryV2";
import { PacienteResponse } from "@/repository/Paciente/PacienteData";
import {
  AlterarSolicitacaoProjetoRequest,
  AlterarSolicitacaoProjetoResponse,
  DadosAcessoRadiologia,
  GerarSolicitacaoProjetoRequest,
  GerarSolicitacaoProjetoResponse,
} from "@/repository/SolicitacaoProjeto/SolicitacaoProjetoData";
import { ArquivosUpload } from "@/util/Interfaces/ArquivosSolicitacaoProjeto";
import {
  GerarSolicitacaoProjetoArquivoRequest,
  GerarSolicitacaoProjetoArquivoResponse,
} from "@/repository/SolicitacaoProjeto/SolicitacaoProjetoArqivoData";

import { Browser } from '@capacitor/browser';


export default defineComponent({
  name: "NovaSolicitacaoProjeto",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonIcon,
    IonItem,
    IonItemGroup,
    IonLabel,
    DadosPaciente,
    DadosTratamentoPaciente,
    DadosTratamento,
    DadosAdicionaisTratamento,
    ImagensPaciente,
    ArquivosSolicitacao,
    DadosCobranca,
  },
  setup() {
    const keyRef = ref<number>(1);
    const setKeyRef = (state: number) => (keyRef.value = state);

    const isLoadingRef = ref<boolean>(false);
    const setIsLoadingRef = (state: boolean) => (isLoadingRef.value = state);

    const dadosSolicitacaoRef = ref<
      GerarSolicitacaoProjetoResponse | AlterarSolicitacaoProjetoResponse
    >();
    const setDadosSolicitacaoRef = (
      state: GerarSolicitacaoProjetoResponse | AlterarSolicitacaoProjetoResponse
    ) => {
      console.log(state);
      dadosSolicitacaoRef.value = state;
    };

    const stepRef = ref<number>(1);
    const setStepRef = (state: number) => {
      if (
        state === 4 &&
        dadosSolicitacaoRef.value &&
        dadosSolicitacaoRef.value.isentoCobranca === "S"
      ) {
        stepRef.value = 5;
      } else {
        stepRef.value = state;
      }
    };

    const pacienteRef = ref<PacienteResponse>();
    const setPacienteRef = (state: PacienteResponse) =>
      (pacienteRef.value = state);

    const dadosTratamentoPacienteRef = ref<any>({});
    const setDadosTratamentoPacienteRef = (state: any) =>
      (dadosTratamentoPacienteRef.value = state);

    const dadosTratamentoRef = ref<any>({});
    const setDadosTratamentoRef = (state: any) =>
      (dadosTratamentoRef.value = state);

    const dadosAdicionaisTratamentoRef = ref<any>({});
    const setDadosAdicionaisTratamentoRef = (state: any) =>
      (dadosAdicionaisTratamentoRef.value = state);

    const tipoEnvioImagensRef = ref<string>();
    const setTipoEnvioImagensRef = (state: string) =>
      (tipoEnvioImagensRef.value = state);

    const arquivoImagensRef = ref<File>();
    const setArquivoImagensRef = (state: File) =>
      (arquivoImagensRef.value = state);

    const tipoEnvioModelosRef = ref<string>();
    const setTipoEnvioModelosRef = (state: string) =>
      (tipoEnvioModelosRef.value = state);

    const arquivosModelosRef = ref<File>();
    const setArquivosModelosRef = (state: File) =>
      (arquivosModelosRef.value = state);

    const arquivoMaxilaRef = ref<File>();
    const setArquivoMaxilaRef = (state: File) =>
      (arquivoMaxilaRef.value = state);

    const arquivoMandibulaRef = ref<File>();
    const setArquivoMandibulaRef = (state: File) =>
      (arquivoMandibulaRef.value = state);

    const dadosAcessoRadioRef = ref<DadosAcessoRadiologia>();
    const setDadosAcessoRadioRef = (state: DadosAcessoRadiologia) =>
      (dadosAcessoRadioRef.value = state);

    const qtdDiasVencimentoRef = ref<number>();
    const setQtdDiasVencimentoRef = (state: number) =>
      (qtdDiasVencimentoRef.value = state);

    const navigation = useRouter();

    const navigate = (routeName: string) => {
      navigation.replace({ name: routeName });
    };

    const openModalError = async (
      title: string,
      message: string,
      color: string
    ) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const buscarListaSolicitacaoElaboracao = async () => {
      setIsLoadingRef(true);
      try {
        const response = await listarSolicitacoesFiltro(10, 1);
        console.log(response.data);
        setIsLoadingRef(false);
      } catch (error) {
        setIsLoadingRef(false);
        openModalError(
          "Erro",
          "Erro ao buscar dados do planejamento",
          "danger"
        );
      }
    };

    const validarDados = async (): Promise<boolean> => {
      if (!pacienteRef.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione um paciente",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (Object.keys(dadosTratamentoRef.value).length === 0) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Sem informações do tratamento",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (!dadosTratamentoRef.value.arcadaMovimentacao) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe quais arcadas movimentar",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (!dadosTratamentoRef.value.desgasteInterProximal) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe se aceita desgaste interproximal",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (!dadosTratamentoRef.value.relacaoMolar) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe relação molar",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (!dadosTratamentoRef.value.linhaSuperiorMedia) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe linha média superior",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (
        dadosTratamentoRef.value.linhaSuperiorMedia === "Corrigir" &&
        !dadosTratamentoRef.value.linhaSuperiorDirecao
      ) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe a direção da linha média superior",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (!dadosTratamentoRef.value.linhaInferiorMedia) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe linha média superior",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else if (
        dadosTratamentoRef.value.linhaInferiorMedia === "Corrigir" &&
        !dadosTratamentoRef.value.linhaInferiorDirecao
      ) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe a direção da linha média inferior",
          buttons: ["Fechar"],
        });
        await alert.present();
        return false;
      } else {
        return true;
      }
    };

    const gerarSolicitacaoProjeto = async () => {
      const dadosValidos = await validarDados();
      if (dadosValidos === true) {
        const request: GerarSolicitacaoProjetoRequest = {
          attachmentDentes: dadosAdicionaisTratamentoRef.value.attachmentDentes,
          paciente: pacienteRef.value ? pacienteRef.value["@id"] : "",
          arcadaMovimentacao: dadosTratamentoRef.value.arcadaMovimentacao,
          desgasteInterProximal: dadosTratamentoRef.value.desgasteInterProximal,
          queixaPaciente: dadosTratamentoPacienteRef.value.queixaPaciente,
          objetivoAlinhadores:
            dadosTratamentoPacienteRef.value.objetivoAlinhadores,
          linhaSuperiorMedia: dadosTratamentoRef.value.linhaSuperiorMedia,
          linhaSuperiorDirecao: dadosTratamentoRef.value.linhaSuperiorDirecao,
          linhaSuperiorMediaMovimento:
            dadosTratamentoRef.value.linhaSuperiorMediaMovimento,
          linhaInferiorMedia: dadosTratamentoRef.value.linhaInferiorMedia,
          linhaInferiorDirecao: dadosTratamentoRef.value.linhaInferiorDirecao,
          linhaInferiorMovimento:
            dadosTratamentoRef.value.linhaInferiorMovimento,
          relacaoMolar: dadosTratamentoRef.value.relacaoMolar,
          implantesDescricao:
            dadosTratamentoPacienteRef.value.implantesDescricao,
          movimentoDentes: dadosAdicionaisTratamentoRef.value.movimentoDentes,
          observacao: dadosAdicionaisTratamentoRef.value.observacao,
          tempoTratamento: dadosAdicionaisTratamentoRef.value.tempoTratamento,
        };

        const loading = await loadingController.create({
          message: "Aguarde...",
          translucent: true,
          backdropDismiss: false,
        });

        try {
          loading.present();
          const response = await gerarSolicitacao(request);
          setDadosSolicitacaoRef(response.data);
          loading.dismiss();
          setStepRef(2);
        } catch (error) {
          loading.dismiss();
          openModalError(
            "Erro",
            "Erro ao gerar solicitação de planejamento",
            "danger"
          );
        }
      }
    };

    const uploadArquivo = async (arquivo: File, linkUpload: string) => {
      return new Promise((resolve, reject) => {
        const reqArquivo = new XMLHttpRequest();
        reqArquivo.open("PUT", linkUpload);
        reqArquivo.setRequestHeader("Content-Type", arquivo.name.slice(-3));
        reqArquivo.onload = () => {
          if (reqArquivo.status === 200) {
            resolve(reqArquivo.response);
          } else {
            reject({
              status: reqArquivo.status,
              statusText: reqArquivo.statusText,
            });
          }
        };
        reqArquivo.onerror = () => {
          reject({
            status: reqArquivo.status,
            statusText: reqArquivo.statusText,
          });
        };
        reqArquivo.send(arquivo);
      });
    };

    const uploadArquivos = async (
      arquivosUp: ArquivosUpload,
      linksUpload: GerarSolicitacaoProjetoArquivoResponse
    ) => {
      if (arquivosUp.arquivoUnicoImagem && arquivoImagensRef.value) {
        const loading = await loadingController.create({
          message: `Enviando Imagens...`,
          backdropDismiss: false,
        });

        await loading.present();
        await uploadArquivo(
          arquivoImagensRef.value,
          linksUpload.linkUploadArquivoUnicoImagem
        );
        await loading.dismiss();
        console.log("esperou Imagens");
      }
      if (arquivosUp.arquivoUnicoModelos && arquivosModelosRef.value) {
        const loading = await loadingController.create({
          message: `Enviando Modelos...`,
          backdropDismiss: false,
        });

        await loading.present();
        await uploadArquivo(
          arquivosModelosRef.value,
          linksUpload.linkUploadArquivoUnicoModelos
        );
        await loading.dismiss();
        console.log("esperou Modelos");
      }
      if (arquivosUp.stlInferior && arquivoMandibulaRef.value) {
        const loading = await loadingController.create({
          message: `Enviando Modelo Mandíbula...`,
          backdropDismiss: false,
        });

        await loading.present();
        await uploadArquivo(
          arquivoMandibulaRef.value,
          linksUpload.linkUploadMandibula
        );
        await loading.dismiss();
        console.log("esperou Mandíbula");
      }
      if (arquivosUp.stlSuperior && arquivoMaxilaRef.value) {
        const loading = await loadingController.create({
          message: `Enviando Modelo Maxila...`,
          backdropDismiss: false,
        });

        await loading.present();
        await uploadArquivo(
          arquivoMaxilaRef.value,
          linksUpload.linkUploadMaxilar
        );
        await loading.dismiss();
        console.log("esperou Maxila");
      }
    };

    const enviarArquivos = async (
      requestAlteracao: AlterarSolicitacaoProjetoRequest,
      aquivosUpload: ArquivosUpload,
      nextStep: number
    ) => {
      if (dadosSolicitacaoRef.value) {
        const loading = await loadingController.create({
          message: "Aguarde...",
          translucent: true,
          backdropDismiss: false,
        });

        try {
          loading.present();
          const responseAlteracao = await alterarSolicitacao(
            dadosSolicitacaoRef.value.codigoSolicitacao,
            requestAlteracao
          );
          setDadosSolicitacaoRef(responseAlteracao.data);

          const requestGerarLinks: GerarSolicitacaoProjetoArquivoRequest = {
            solicitacaoProjeto: "/solicitacao_projeto/20210999920027",
            operacao: "U",
          };
          const response = await gerarLinkArquivos(requestGerarLinks);
          loading.dismiss();
          await uploadArquivos(aquivosUpload, response.data);
          setStepRef(nextStep);
        } catch (error) {
          loading.dismiss();
          openModalError(
            "Erro",
            "Erro ao gerar solicitação de planejamento",
            "danger"
          );
        }
      }
    };

    const verificarArquivosImagens = async () => {
      console.log("arquivo");
      console.log(arquivoImagensRef.value?.name.slice(-3));

      if (!arquivoImagensRef.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione o arquivo",
          buttons: ["Fechar"],
        });
        alert.present();
      } else {
        const requestAlteracao: AlterarSolicitacaoProjetoRequest = {
          extArquivoUnicoImagem: arquivoImagensRef.value?.name.slice(-3),
          operacao: "I",
        };
        const aquivosUpload: ArquivosUpload = {
          arquivoUnicoImagem: true,
        };
        enviarArquivos(requestAlteracao, aquivosUpload, 3);
      }
    };

    const verificarArquivosModelos = async () => {
      console.log("arquivo");
      console.log(arquivosModelosRef.value?.name.slice(-3));

      if (!arquivosModelosRef.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione o arquivo",
          buttons: ["Fechar"],
        });
        alert.present();
      } else {
        const requestAlteracao: AlterarSolicitacaoProjetoRequest = {
          extArquivoUnicoModelos: arquivosModelosRef.value?.name.slice(-3),
          operacao: "M",
        };
        const aquivosUpload: ArquivosUpload = {
          arquivoUnicoModelos: true,
        };
        enviarArquivos(requestAlteracao, aquivosUpload, 4);
      }
    };

    const verificarArquivosModelosIndi = async () => {
      console.log("arquivo");
      console.log(arquivoMaxilaRef.value?.name.slice(-3));
      console.log(arquivoMandibulaRef.value?.name.slice(-3));

      if (!arquivoMaxilaRef.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione o modelo da maxila",
          buttons: ["Fechar"],
        });
        alert.present();
      } else if (!arquivoMandibulaRef.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione o modelo da mandíbula",
          buttons: ["Fechar"],
        });
        alert.present();
      } else {
        const requestAlteracao: AlterarSolicitacaoProjetoRequest = {
          extArquivoStlSuperior: arquivoMaxilaRef.value?.name.slice(-3),
          extArquivoStInferior: arquivoMandibulaRef.value?.name.slice(-3),
          operacao: "M",
        };
        const aquivosUpload: ArquivosUpload = {
          stlInferior: true,
          stlSuperior: true,
        };
        enviarArquivos(requestAlteracao, aquivosUpload, 4);
      }
    };

    const enviarDadosAcessoRadio = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      if (dadosAcessoRadioRef.value && dadosSolicitacaoRef.value) {
        try {
          loading.present();
          const requestAlteracao: AlterarSolicitacaoProjetoRequest = {
            dadosAcessoRadiologia: dadosAcessoRadioRef.value,
          };

          const response = await alterarSolicitacao(
            dadosSolicitacaoRef.value.codigoSolicitacao,
            requestAlteracao
          );
          setDadosSolicitacaoRef(response.data);
          loading.dismiss();
          setStepRef(4);
        } catch (error) {
          loading.dismiss();
          openModalError(
            "Erro",
            "Erro ao gerar solicitação de planejamento",
            "danger"
          );
        }
      } else {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Informe os dados de acesso a radiologia",
          buttons: ["Fechar"],
        });
        alert.present();
      }
    };

    const tratarImagensPacientes = async () => {
      const alert = await alertController.create({
        header: "Atenção",
        message: "Selecione um tipo de envio",
        buttons: ["Fechar"],
      });
      switch (tipoEnvioImagensRef.value) {
        case "unico":
          verificarArquivosImagens();
          break;
        case "naoEnviar":
          setStepRef(3);
          break;
        default:
          alert.present();
          break;
      }
    };

    const tratarEnvioModelos = async () => {
      console.log("Modelos");
      console.log(dadosAcessoRadioRef);
      console.log(arquivosModelosRef.value);
      console.log(arquivoMaxilaRef.value);
      console.log(arquivoMandibulaRef.value);
      const alert = await alertController.create({
        header: "Atenção",
        message: "Selecione um tipo de envio",
        buttons: ["Fechar"],
      });
      switch (tipoEnvioModelosRef.value) {
        case "unico":
          verificarArquivosModelos();
          break;
        case "individuais":
          verificarArquivosModelosIndi();
          break;
        case "acessoRadiologia":
          enviarDadosAcessoRadio();
          break;
        default:
          alert.present();
          break;
      }
    };

    const finalizarSolicitacao = async () => {
      if (dadosSolicitacaoRef.value) {
        const loading = await loadingController.create({
          message: "Aguarde...",
          translucent: true,
          backdropDismiss: false,
        });

        try {
          loading.present();
          const requestAlteracao: AlterarSolicitacaoProjetoRequest = {
            operacao: "F",
            cobranca: {
              diasVencimento: qtdDiasVencimentoRef.value,
            },
          };

          const response = await alterarSolicitacao(
            dadosSolicitacaoRef.value.codigoSolicitacao,
            requestAlteracao
          );
          setDadosSolicitacaoRef(response.data);
          loading.dismiss();
          setStepRef(5);
        } catch (error) {
          loading.dismiss();
          openModalError("Erro", "Erro ao finalizar solicitação", "danger");
        }
      }
    };

    const tratarCobranca = async () => {
      console.log("cobranca");
      console.log(qtdDiasVencimentoRef.value);
      if (qtdDiasVencimentoRef.value) {
        finalizarSolicitacao();
      } else {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione uma data de vencimento",
          buttons: ["Fechar"],
        });
        alert.present();
      }
    };

    const recuperarBoleto = async () => {
      if (dadosSolicitacaoRef.value) {
        await Browser.open({
          url: dadosSolicitacaoRef.value?.cobranca.linkBoleto,
        });
      }
    };

    onIonViewWillEnter(() => {
      setKeyRef(keyRef.value + 1);
      buscarListaSolicitacaoElaboracao();
    });

    return {
      navigate,
      isLoadingRef,
      closeOutline,
      arrowForwardCircleOutline,
      barcodeOutline,
      checkmarkCircleOutline,
      gerarSolicitacaoProjeto,
      dadosSolicitacaoRef,
      setPacienteRef,
      setDadosTratamentoPacienteRef,
      setDadosTratamentoRef,
      setDadosAdicionaisTratamentoRef,
      setArquivoImagensRef,
      setTipoEnvioImagensRef,
      setTipoEnvioModelosRef,
      setArquivosModelosRef,
      setArquivoMaxilaRef,
      setArquivoMandibulaRef,
      setDadosAcessoRadioRef,
      stepRef,
      tratarImagensPacientes,
      tratarEnvioModelos,
      setQtdDiasVencimentoRef,
      tratarCobranca,
      recuperarBoleto,
      keyRef,
    };
  },
});
</script>

<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Cobrança
      </ion-label>
    </ion-item-divider>
    <ion-item-group v-if="dadosServicoRef">
      <ion-item>
        <ion-label class="ion-text-wrap">
          <h3>
            Atenção! Será cobrado o valor de
            {{
              dadosServicoRef.valorServico.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
            pela confecção do planejamento.
          </h3>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Data Vencimento</ion-label>
        <ion-datetime
          v-model="dtVencimento"
          @ionChange="alteracaoDataVencimento()"
          placeholder="Escolha"
          required
          display-format="DD/MM/YYYY"
          picker-format="DD/MM/YYYY"
          :min="minDateRef"
          :max="maxDateRef"
        ></ion-datetime>
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonDatetime,
  loadingController,
  modalController,
} from "@ionic/vue";

import { defineComponent, onBeforeMount, ref } from "vue";

import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";

import { buscaServicos } from "@/repository/Servico/ServicoRepositoryV2";
import {
  GetServiceListaParamsRequest,
  GetServicoResponse,
} from "@/repository/Servico/ServicoData";

export default defineComponent({
  name: "DadosCobranca",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonDatetime,
    IonLabel,
  },
  emits: ["atualizarQtdDiasVencimento"],
  setup(props, { emit }) {
    const dadosServicoRef = ref<GetServicoResponse>();
    const setDadosServicoRef = (state: GetServicoResponse) =>
      (dadosServicoRef.value = state);

    const minDateRef = ref<string>(new Date().toISOString());
    const maxDateRef = ref<string>(new Date().toISOString());
    const setMaxDateRef = (state: string) => (maxDateRef.value = state);

    const dtVencimento = ref();

    const openModalError = async (
      title: string,
      message: string,
      color: string
    ) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const calcMaxDate = () => {
      const date = new Date();
      if (dadosServicoRef.value) {
        console.log(dadosServicoRef.value);
        date.setDate(
          date.getDate() + dadosServicoRef.value.prazoPagamentoQtdDias
        );
        setMaxDateRef(date.toISOString());
        console.log("maxdate", maxDateRef);
      } else {
        setMaxDateRef(date.toISOString());
      }
    };

    const buscarServico = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      try {
        loading.present();
        const param: GetServiceListaParamsRequest = {
          codigoTipoServico: 100,
          indicadorValidade: 1,
        };
        const response = await buscaServicos(param);
        setDadosServicoRef(response.data["hydra:member"][0]);
        calcMaxDate();
        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        openModalError("Erro", "Erro dados do serviço", "danger");
      }
    };

    const alteracaoDataVencimento = () => {
      const expDay = Date.parse(dtVencimento.value);
      const qtdDias = Math.ceil(
        (expDay - new Date().getTime()) / (1000 * 3600 * 24)
      );
      emit("atualizarQtdDiasVencimento", qtdDias);
    };

    onBeforeMount(() => {
      buscarServico();
    });

    return {
      dadosServicoRef,
      minDateRef,
      maxDateRef,
      dtVencimento,
      alteracaoDataVencimento,
    };
  },
});
</script>


import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import axios from "axios";

import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonModal,
  loadingController,
  toastController,
} from "@ionic/vue";

import { logIn, lockOpen } from "ionicons/icons";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import ModalLostPassword from "@/views/login/ModalLostPassword.vue";

import {
  getUserLogged,
  setSessionToken,
} from "@/service/storage/SessionStorageService";

const menu = defineComponent({
  setup() {
    const router = useRouter();

    const email = ref<string>("");
    const password = ref<string>("");
    const isOpenodalLostPasswordRef = ref(false);

    const setOpenModalLostPassword = (state: boolean) =>
      (isOpenodalLostPasswordRef.value = state);

    const login = async () => {
      const payload = {
        email: email.value,
        password: password.value,
      };

      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      try {
        loading.present();
        const response = await axios.post(
          `${process.env.VUE_APP_BLUE_ALIGNER_API}/authentication_token`,
          payload,
          {
            withCredentials: true,
          }
        );

        if (!response.data && !response.data.token) {
          throw new Error("Erro ao gerar token de sessão.");
        } else {
          await setSessionToken(response.data.token);
        }

        await getUserLogged();

        router.replace({ name: "Home" });

        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        const toast = await toastController.create({
          color: "danger",
          message: error.message,
          duration: 2000,
        });
        toast.present();
        router.push({ name: "Login" });
      }
    };

    return {
      email,
      password,
      login,
      logIn,
      lockOpen,
      isOpenodalLostPasswordRef,
      setOpenModalLostPassword,
    };
  },
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
    BluealModal,
    IonModal,
    ModalLostPassword,
  },
});

export default menu;

<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-icon
            size="large"
            :icon="closeCircleOutline"
            @click="navigate('Home')"
          ></ion-icon>
        </ion-buttons>
        <ion-title>Novo Pedido Alinhadores</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list v-if="!loading">
        <ion-list-header lines="full">
          <ion-label>
            <h2>
              {{ `Planejamentos Aptos ` }}
              <ion-badge>
                {{ qtdProjetosAptos }}
              </ion-badge>
            </h2>
          </ion-label>
        </ion-list-header>
        <ion-item
          v-for="item in listaProjetosAptos"
          :key="item.nrProjeto"
          @click="
            navigate('NovoPedidoAlinhadores', { nrProjeto: item.nrProjeto })
          "
        >
          <ion-label>
            <p>Número Proejto</p>
            <h2>{{ item.nrProjeto }}</h2>
          </ion-label>
          <ion-label>
            <p>Paciente</p>
            <h2>{{ item.solicitacaoProjeto.paciente.nome }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-if="loading">
        <ion-list-header lines="full">
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1rem"
            ></ion-skeleton-text>
          </ion-label>
        </ion-list-header>
        <ion-item v-for="i in 10" :key="i">
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1.7rem"
            ></ion-skeleton-text>
          </ion-label>
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1.7rem"
            ></ion-skeleton-text>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonIcon,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonBadge,
  IonButtons,
} from "@ionic/vue";

import { closeCircleOutline } from "ionicons/icons";

import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";

import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";

export default {
  name: "ListaProjetosAptosPedido",
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonItem,
    IonLabel,
    IonIcon,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonBadge,
    IonButtons,
  },
  data() {
    return {
      loading: true,
      qtdPaginasTodas: 1,
      listaProjetosAptos: null,
      qtdProjetosAptos: 0,
      closeCircleOutline: closeCircleOutline,
    };
  },
  setup() {
    const isOpenModalPedidoAlinhadoresRef = ref(false);

    const navigation = useRouter();

    const navigate = (routeName, routeParams) => {
      const params = routeParams ? routeParams : {};
      navigation.push({ name: routeName, params: params });
    };

    const setOpenModalPedidoAlinhadoresRef = (state) =>
      (isOpenModalPedidoAlinhadoresRef.value = state);

    return {
      isOpenModalPedidoAlinhadoresRef,
      setOpenModalPedidoAlinhadoresRef,
      navigate,
    };
  },
  ionViewWillEnter() {
    this.listarProjetosAptos(1);
  },
  methods: {
    async listarProjetosAptos(page) {
      this.loading = true;
      try {
        const params = {
          estadoProjeto: 30,
          page: page,
        };
        const response = await projetoRepositoryV2.buscaProjetoPorSolicitacao(
          params
        );
        page === 1 ? (this.listaProjetosAptos = []) : null;
        this.listaProjetosAptos === null
          ? (this.listaProjetosAptos = response.data["hydra:member"])
          : (this.listaProjetosAptos = this.listaProjetosAptos.concat(
              response.data["hydra:member"]
            ));
        this.qtdProjetosAptos = this.listaProjetosAptos.length;
        this.loading = false;
        this.qtdPaginasTodas = page;
      } catch (error) {
        this.loading = false;
      }
    },
    reset() {
      this.listarProjetosAptos(1);
    },
  },
};
</script>


import {
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ArquivosSolicitacao",
  components: {
    IonList,
    IonItemDivider,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
  },
  emits: [
    "atualizarArquivosModelos",
    "atualizarDadosAcessoRadiologia",
    "atualizarTipoEnvioModelos",
    "atualizarArquivoMaxila",
    "atualizarArquivoMandibula",
  ],
  setup(props, { emit }) {
    const uploadOption = ref("");

    const usuarioAcesso = ref<string>();

    const senhaAcesso = ref<string>();

    const urlAcesso = ref<string>();

    const alteracaoArquivosModelos = (files: File[]) => {
      emit("atualizarArquivosModelos", files[0]);
    };

    const alteracaoArquivoMaxila = (files: File[]) => {
      emit("atualizarArquivoMaxila", files[0]);
    };

    const alteracaoArquivoMandibula = (files: File[]) => {
      emit("atualizarArquivoMandibula", files[0]);
    };

    const alteracaoDadosAcessoRadiologia = () => {
      emit("atualizarDadosAcessoRadiologia", {
        usuario: usuarioAcesso.value,
        senha: senhaAcesso.value,
        url: urlAcesso.value,
      });
    };

    const alteracaoTipoEnvioModelos = () => {
      emit("atualizarTipoEnvioModelos", uploadOption.value);
    };

    return {
      uploadOption,
      closeOutline,
      alteracaoArquivosModelos,
      alteracaoDadosAcessoRadiologia,
      alteracaoTipoEnvioModelos,
      alteracaoArquivoMaxila,
      alteracaoArquivoMandibula,
      usuarioAcesso,
      senhaAcesso,
      urlAcesso,
    };
  },
});

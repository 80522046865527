<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Arquivos Solicitação
      </ion-label>
    </ion-item-divider>
    <ion-item-group>
      <ion-item>
        <ion-label>Tipo de Envio</ion-label>
        <ion-select
          v-model="uploadOption"
          :value="uploadOption"
          @ionChange="alteracaoTipoEnvioModelos()"
          placeholder="Selecione"
        >
          <ion-select-option value="individuais">Individual</ion-select-option>
          <ion-select-option value="unico">Único</ion-select-option>
          <ion-select-option value="acessoRadiologia"
            >Acesso Radiologia</ion-select-option
          >
        </ion-select>
      </ion-item>
    </ion-item-group>
    <ion-item-group v-if="uploadOption === 'unico'">
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Arquivo Único (zip, rar)</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          @change="alteracaoArquivosModelos($event.target.files)"
          name="arquivo"
          color="primary"
          type="file"
          accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, application/x-rar-compressed, application/octet-stream"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group v-if="uploadOption === 'individuais'">
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Modelo Maxilar</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          @change="alteracaoArquivoMaxila($event.target.files)"
          name="arquivo"
          color="primary"
          type="file"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Modelo Mandíbula</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          @change="alteracaoArquivoMandibula($event.target.files)"
          name="arquivo"
          color="primary"
          type="file"
        ></ion-input>
      </ion-item>
    </ion-item-group>
    <ion-item-group v-if="uploadOption === 'acessoRadiologia'">
      <ion-item>
        <ion-label position="floating"><p>Usuário</p></ion-label>
        <ion-input
          @ionChange="alteracaoDadosAcessoRadiologia()"
          v-model="usuarioAcesso"
          minlength="4"
          required
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating"><p>Senha</p></ion-label>
        <ion-input
          @ionChange="alteracaoDadosAcessoRadiologia()"
          v-model="senhaAcesso"
          minlength="3"
          required
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating"><p>Site (url)</p></ion-label>
        <ion-input
          @ionChange="alteracaoDadosAcessoRadiologia()"
          v-model="urlAcesso"
          minlength="3"
          required
        ></ion-input>
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ArquivosSolicitacao",
  components: {
    IonList,
    IonItemDivider,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
  },
  emits: [
    "atualizarArquivosModelos",
    "atualizarDadosAcessoRadiologia",
    "atualizarTipoEnvioModelos",
    "atualizarArquivoMaxila",
    "atualizarArquivoMandibula",
  ],
  setup(props, { emit }) {
    const uploadOption = ref("");

    const usuarioAcesso = ref<string>();

    const senhaAcesso = ref<string>();

    const urlAcesso = ref<string>();

    const alteracaoArquivosModelos = (files: File[]) => {
      emit("atualizarArquivosModelos", files[0]);
    };

    const alteracaoArquivoMaxila = (files: File[]) => {
      emit("atualizarArquivoMaxila", files[0]);
    };

    const alteracaoArquivoMandibula = (files: File[]) => {
      emit("atualizarArquivoMandibula", files[0]);
    };

    const alteracaoDadosAcessoRadiologia = () => {
      emit("atualizarDadosAcessoRadiologia", {
        usuario: usuarioAcesso.value,
        senha: senhaAcesso.value,
        url: urlAcesso.value,
      });
    };

    const alteracaoTipoEnvioModelos = () => {
      emit("atualizarTipoEnvioModelos", uploadOption.value);
    };

    return {
      uploadOption,
      closeOutline,
      alteracaoArquivosModelos,
      alteracaoDadosAcessoRadiologia,
      alteracaoTipoEnvioModelos,
      alteracaoArquivoMaxila,
      alteracaoArquivoMandibula,
      usuarioAcesso,
      senhaAcesso,
      urlAcesso,
    };
  },
});
</script>

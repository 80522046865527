<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Informações Tratamento
      </ion-label>
    </ion-item-divider>
    <ion-item-group>
      <ion-radio-group
        aria-required="true"
        v-model="arcadasMovimentacaoRef"
        value=""
        @ionChange="emitirDadosTratamento()"
      >
        <ion-list-header>
          <ion-label><h3>Quais Arcadas Deseja Movimentar</h3></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Superior</ion-label>
          <ion-radio slot="start" value="Superior"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Inferior</ion-label>
          <ion-radio slot="start" value="Inferior"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Ambas</ion-label>
          <ion-radio slot="start" value="Ambas"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-item-group>
    <ion-item-group>
      <ion-radio-group
        v-model="desgasteInterProximal"
        value=""
        @ionChange="emitirDadosTratamento()"
      >
        <ion-list-header>
          <ion-label><h3>Desgate Interproximal</h3></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Sim</ion-label>
          <ion-radio slot="start" value="SIM"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Não</ion-label>
          <ion-radio slot="start" value="NAO"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-item-group>
    <ion-item-group>
      <ion-radio-group
        v-model="relacaoMolar"
        value=""
        @ionChange="emitirDadosTratamento()"
      >
        <ion-list-header>
          <ion-label><h3>Relação Molar</h3></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Manter</ion-label>
          <ion-radio slot="start" value="Manter"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Melhorar</ion-label>
          <ion-radio slot="start" value="Melhorar"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Corrigir</ion-label>
          <ion-radio slot="start" value="Corrigir"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-item-group>
    <ion-item-group style="margin-top: 1.2rem">
      <ion-item lines="none">
        <ion-label><h3>Linha Média Superior</h3></ion-label>
      </ion-item>
      <ion-item :lines="possuiLinhaMediaSuperiorRef ? 'none' : 'inset'">
        <ion-segment
          @ionChange="
            setPossuiLinhaMediaSuperiorRef(!possuiLinhaMediaSuperiorRef);
            emitirDadosTratamento();
          "
          :value="possuiLinhaMediaSuperiorRef"
        >
          <ion-segment-button :value="false">
            <ion-label>Manter</ion-label>
          </ion-segment-button>
          <ion-segment-button :value="true">
            <ion-label>Corrigir</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-radio-group
        v-if="possuiLinhaMediaSuperiorRef"
        v-model="linhaMediaSuperiorDirecaoRef"
        value=""
        @ionChange="emitirDadosTratamento()"
      >
        <ion-list-header>
          <ion-label><h3>Direção</h3></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Direita</ion-label>
          <ion-radio slot="start" value="Direita"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Esquerda</ion-label>
          <ion-radio slot="start" value="Esquerda"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-item v-if="possuiLinhaMediaSuperiorRef">
        <ion-label position="floating"><h3>Distância</h3></ion-label>
        <ion-input
          v-model="linhaMediaSuperiorDistanciaRef"
          @ionChange="emitirDadosTratamento()"
          :required="possuiLinhaMediaSuperiorRef"
          :minlength="2"
          placeholder="Ex. 1mm"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group style="margin-top: 1.2rem">
      <ion-item lines="none">
        <ion-label><h3>Linha Média Inferior</h3></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-segment
          @ionChange="
            setPossuiLinhaMediaInferiorRef(!possuiLinhaMediaInferiorRef);
            emitirDadosTratamento();
          "
          :value="possuiLinhaMediaInferiorRef"
        >
          <ion-segment-button :value="false">
            <ion-label>Manter</ion-label>
          </ion-segment-button>
          <ion-segment-button :value="true">
            <ion-label>Corrigir</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-radio-group
        v-if="possuiLinhaMediaInferiorRef"
        v-model="linhaMediaInferiorDirecaoRef"
        value=""
        @ionChange="emitirDadosTratamento()"
      >
        <ion-list-header>
          <ion-label><h3>Direção</h3></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Direita</ion-label>
          <ion-radio slot="start" value="Direita"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>Esquerda</ion-label>
          <ion-radio slot="start" value="Esquerda"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-item v-if="possuiLinhaMediaInferiorRef" lines="none">
        <ion-label position="floating"><h3>Distância</h3></ion-label>
        <ion-input
          v-model="linhaMediaInferiorDistanciaRef"
          @ionChange="emitirDadosTratamento()"
          :required="possuiLinhaMediaInferiorRef"
          :minlength="2"
          placeholder="Ex. 1mm"
        />
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonListHeader,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosTratamento",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonListHeader,
    IonRadioGroup,
    IonRadio,
    IonInput,
    IonSegment,
    IonSegmentButton,
  },
  emits: ["dadosTratamentoAlteracao"],
  setup(props, { emit }) {
    const arcadasMovimentacaoRef = ref<string | null>(null);
    const relacaoMolar = ref<string | null>(null);
    const implantesRef = ref<string | null>(null);
    const desgasteInterProximal = ref<string | null>(null);

    const linhaMediaSuperiorDirecaoRef = ref<string | null>(null);
    const linhaMediaSuperiorDistanciaRef = ref<string | null>(null);
    const possuiLinhaMediaSuperiorRef = ref<boolean>(false);
    const setPossuiLinhaMediaSuperiorRef = (state: boolean) => {
      possuiLinhaMediaSuperiorRef.value = state;
      if (!state) {
        linhaMediaSuperiorDirecaoRef.value = null;
        linhaMediaSuperiorDistanciaRef.value = null;
      }
    };

    const linhaMediaInferiorDirecaoRef = ref<string | null>(null);
    const linhaMediaInferiorDistanciaRef = ref<string | null>(null);
    const possuiLinhaMediaInferiorRef = ref<boolean>(false);
    const setPossuiLinhaMediaInferiorRef = (state: boolean) => {
      possuiLinhaMediaInferiorRef.value = state;
      if (!state) {
        linhaMediaInferiorDirecaoRef.value = null;
        linhaMediaInferiorDistanciaRef.value = null;
      }
    };

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        arcadaMovimentacao: arcadasMovimentacaoRef.value,
        desgasteInterProximal: desgasteInterProximal.value,
        linhaSuperiorMedia: possuiLinhaMediaSuperiorRef.value
          ? "Corrigir"
          : "Manter",
        linhaSuperiorDirecao: linhaMediaSuperiorDirecaoRef.value,
        linhaSuperiorMediaMovimento: linhaMediaSuperiorDistanciaRef.value,
        linhaInferiorMedia: possuiLinhaMediaInferiorRef.value
          ? "Corrigir"
          : "Manter",
        linhaInferiorDirecao: linhaMediaInferiorDirecaoRef.value,
        linhaInferiorMovimento: linhaMediaInferiorDistanciaRef.value,
        relacaoMolar: relacaoMolar.value,
      };

      emit("dadosTratamentoAlteracao", dadosTratametno);
    };

    return {
      arcadasMovimentacaoRef,
      relacaoMolar,
      implantesRef,
      desgasteInterProximal,
      linhaMediaSuperiorDirecaoRef,
      linhaMediaSuperiorDistanciaRef,
      possuiLinhaMediaSuperiorRef,
      setPossuiLinhaMediaSuperiorRef,
      linhaMediaInferiorDirecaoRef,
      linhaMediaInferiorDistanciaRef,
      possuiLinhaMediaInferiorRef,
      setPossuiLinhaMediaInferiorRef,
      emitirDadosTratamento,
    };
  },
});
</script>

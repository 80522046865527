<template>
  <ion-page>
    <ion-content>
      <ion-list>
        <ion-list-header>{{
          tipoLista === "superior"
            ? "Alinhadores Superiores"
            : "Alinhadores Inferiores"
        }}</ion-list-header>
        <ion-item v-for="item in listaAlinhadores" :key="item.alinhador">
          <ion-label>{{ item.alinhador }}</ion-label>
          <ion-checkbox
            color="primary"
            :disabled="item.pedido"
            slot="start"
            :modelValue="item.pedido"
            @update:modelValue="atualizarListaSelecao($event, item.alinhador)"
          />
        </ion-item>
      </ion-list>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size="6">
            <ion-button
              @click="confirmar()"
              expand="block"
              color="tertiary"
              shape="round"
              strong
            >
              Confirmar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ListaAlinhadoresPedido",
  props: {
    listaAlinhadores: Array,
    tipoLista: String,
  },
  components: {
    IonPage,
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonCheckbox,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
  },
  emits: ["atualizarLista"],
  setup(props, { emit }) {
    let listaAlinhadoresSelecao = [];

    const atualizarListaSelecao = (value, alinhador) => {
      let listaAux = [...listaAlinhadoresSelecao];
      if (value) {
        listaAux.push(alinhador);
      } else {
        listaAux = [
          ...listaAlinhadoresSelecao.filter((item) => {
            return item !== alinhador;
          }),
        ];
      }
      listaAlinhadoresSelecao = [...listaAux];
    };

    const confirmar = () => {
      emit("atualizarLista", {
        lista: props.tipoLista,
        listaSelecao: listaAlinhadoresSelecao,
      });
    };

    return { atualizarListaSelecao, confirmar };
  },
});
</script>

import Repository from './Repository';
import qs from 'qs';

const resource = '/servicos';

export default{
    buscaServicos(params){
        return Repository.get(
            `${resource}`,
            {
                params : {
                    ...params
                },
                paramsSerializer : (params) => qs.stringify(params, { arrayFormat: 'repeat' })
            }
        );
    },
}

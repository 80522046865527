<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-icon
            size="large"
            :icon="closeCircleOutline"
            @click="returnHome()"
          ></ion-icon>
        </ion-buttons>
        <ion-title>Planejamentos</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list v-if="!loading">
        <ion-list-header lines="full">
          <ion-label>
            <h2>
              {{ textTitleList }}
              <ion-badge>
                {{ qtdListaSolicitacoes }}
              </ion-badge>
            </h2>
          </ion-label>
          <ion-button
            strong
            size="small"
            color="primary"
            @click="setIsOpenFilterPopOverRef(true, $event)"
            shape="round"
            fill="outline"
          >
            ALTERAR FILTRO
          </ion-button>
        </ion-list-header>
        <ion-item-group>
          <ion-item
            v-for="item in listaSolicitacoes"
            :key="item.codigoSolicitacao"
            @click="
              codigoSolicitacaoSelected = item.codigoSolicitacao;
              setOpenModalConsultaRef(true);
            "
          >
            <ion-label position="stacked">
              <p>Código</p>
              <h2>
                {{ item.codigoSolicitacao }}
              </h2>
            </ion-label>
            <ion-note slot="end">
              {{
                new Date(item.dataHoraSolicitacao).toLocaleDateString("pt-BR", {
                  timeZone: "UTC",
                })
              }}
            </ion-note>
            <ion-label position="stacked">
              <p>Paciente</p>
              <h2>{{ item.nomePaciente }}</h2>
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
      <ion-list v-if="loading">
        <ion-list-header lines="full">
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 95%; height: 1rem"
            ></ion-skeleton-text>
          </ion-label>
        </ion-list-header>
        <ion-item-group>
          <ion-item v-for="n in 10" :key="n">
            <ion-label position="stacked">
              <ion-skeleton-text
                animated
                style="width: 90%; height: 1.6rem"
              ></ion-skeleton-text>
            </ion-label>
            <ion-label position="stacked">
              <ion-skeleton-text
                animated
                style="width: 90%; height: 1.6rem"
              ></ion-skeleton-text>
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>
      <ion-popover
        :is-open="isOpenFilterPopOverRef"
        :event="eventPopOver"
        :translucent="true"
        :backdrop-dismiss="false"
      >
        <ion-item-group>
          <ion-item
            @click="
              textTitleList = 'Em Elaboração';
              listarSolicitacoes(1, 30);
              setIsOpenFilterPopOverRef(false, eventPopOver);
            "
          >
            <ion-label>
              Em Elaboração
            </ion-label>
          </ion-item>
          <ion-item
            @click="
              textTitleList = 'Pendente Aceite';
              listarSolicitacoes(1, 40);
              setIsOpenFilterPopOverRef(false, eventPopOver);
            "
          >
            <ion-label>
              Pendente Aceite
            </ion-label>
          </ion-item>
          <ion-item
            @click="
              textTitleList = 'Aceito';
              listarSolicitacoes(1, 60);
              setIsOpenFilterPopOverRef(false, eventPopOver);
            "
          >
            <ion-label>
              Aceito
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            @click="
              textTitleList = 'Recusado';
              listarSolicitacoes(1, 50);
              setIsOpenFilterPopOverRef(false, eventPopOver);
            "
          >
            <ion-label>
              Recusado
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-popover>
      <ion-modal
        :is-open="isOpenModalConsultaRef"
        @onDidDismiss="setOpenModalConsultaRef(false)"
      >
        <BluealModal
          title="Solicitação de Planejamento"
          color="tertiary"
          :closeModal="setOpenModalConsultaRef"
        >
          <template v-slot:content>
            <ModalConsultarSolicitacaoProjeto
              :codigoSolicitacao="codigoSolicitacaoSelected"
              @atualizarLista="reset()"
            />
          </template>
        </BluealModal>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonItemGroup,
  IonNote,
  IonLabel,
  IonIcon,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonBadge,
  IonButtons,
  IonButton,
  IonPopover,
  IonModal,
} from "@ionic/vue";

import {
  filterOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
  closeCircleOutline,
  documentTextOutline,
} from "ionicons/icons";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import ModalConsultarSolicitacaoProjeto from "@/views/solicitacao-projeto/consulta-solicitacao/ModalConsultarSolicitacaoProjeto.vue";
import solicitacaoProjetoListaV2Repository from "@/repository/solicitacaoProjetoListaV2Repository";
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";

export default {
  name: "ConsultaSolicitacoes",
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonIcon,
    IonList,
    IonNote,
    IonListHeader,
    IonSkeletonText,
    IonBadge,
    IonButtons,
    IonButton,
    IonPopover,
    ModalConsultarSolicitacaoProjeto,
    IonModal,
    BluealModal,
  },
  data() {
    return {
      loading: true,
      codigoSolicitacaoSelected: "",
      qtdPaginasTodas: 1,
      listaSolicitacoes: [],
      qtdListaSolicitacoes: 0,
      textTitleList: "Em Elaboração",
      filterOutline: filterOutline,
      checkmarkCircleOutline: checkmarkCircleOutline,
      alertCircleOutline: alertCircleOutline,
      closeCircleOutline: closeCircleOutline,
      documentTextOutline: documentTextOutline,
    };
  },
  setup() {
    const navigate = useRouter();
    const isOpenModalConsultaRef = ref(false);
    const eventPopOver = ref();
    const isOpenFilterPopOverRef = ref(false);
    const setIsOpenFilterPopOverRef = (state, event) => {
      if (event) eventPopOver.value = event;
      isOpenFilterPopOverRef.value = state;
    };

    const setOpenModalConsultaRef = (state) =>
      (isOpenModalConsultaRef.value = state);

    const returnHome = () => {
      navigate.push({ name: "Home" });
    };

    return {
      returnHome,
      isOpenModalConsultaRef,
      setOpenModalConsultaRef,
      eventPopOver,
      isOpenFilterPopOverRef,
      setIsOpenFilterPopOverRef,
    };
  },
  ionViewWillEnter() {
    this.listarSolicitacoes(1, 30);
  },
  methods: {
    async listarSolicitacoes(page, estado) {
      this.loading = true;
      try {
        const response = await solicitacaoProjetoListaV2Repository.listarSolicitacoesFiltro(
          estado,
          page
        );
        this.listaSolicitacoes = response.data["hydra:member"];
        this.qtdListaSolicitacoes = response.data["hydra:totalItems"];
        this.loading = false;
        this.qtdPaginasTodas = page;
      } catch (e) {
        this.loading = false;
        this.$buefy.dialog.alert({
          title: "Erro",
          message: e.response.data["hydra:description"],
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
    },
    reset() {
      this.textTitleList = "Em Elaboração";
      this.listarSolicitacoes(1, 30);
    },
  },
};
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.painel-meus-planejamentos {
  padding: 1rem;
}
</style>

<template>
  <ion-content>
    <ion-list v-if="!isLoadingRef">
      <ion-item-divider>
        <ion-label>
          Selecione um paciente
        </ion-label>
      </ion-item-divider>
      <ion-item
        v-for="item in listaPacientesRef"
        :key="item.codigoPaciente"
        @click="selecionarPaciente(item)"
      >
        <ion-label>
          <p>Nome</p>
          <h2>{{ item.nome }}</h2>
        </ion-label>
        <ion-note slot="end">{{
          item.dataNascimento
            ? new Date(item.dataNascimento).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              })
            : ""
        }}</ion-note>
      </ion-item>
    </ion-list>
    <ion-list v-if="isLoadingRef">
      <ion-item-divider>
        <ion-label>
          Carregando Lista...
        </ion-label>
      </ion-item-divider>
      <ion-item v-for="n in 10" :key="n">
        <ion-label>
          <ion-skeleton-text animated style="width: 80%; height: 1.7rem">
          </ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import {
  IonContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonNote,
} from "@ionic/vue";

import { defineComponent, onBeforeMount, ref } from "vue";

import { buscaPacientes } from "@/repository/Paciente/PacienteRepositoryV2";
import { PacienteResponse } from "@/repository/Paciente/PacienteData";

export default defineComponent({
  components: {
    IonContent,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
    IonNote,
  },
  emits: ["pacienteSelecionado", "fecharModal"],
  setup(props, { emit }) {
    const isLoadingRef = ref<boolean>(false);
    const setIsLoadingRef = (state: boolean) => (isLoadingRef.value = state);

    const listaPacientesRef = ref<PacienteResponse[]>([]);
    const setListaPacientesRef = (state: PacienteResponse[]) =>
      (listaPacientesRef.value = state);

    const listarPaciente = async () => {
      setIsLoadingRef(true);
      try {
        const response = await buscaPacientes();
        setListaPacientesRef(response.data["hydra:member"]);
        setIsLoadingRef(false);
      } catch (error) {
        setIsLoadingRef(false);
      }
    };

    const selecionarPaciente = (paciente: PacienteResponse) => {
      emit("pacienteSelecionado", paciente);
      emit("fecharModal");
    };

    onBeforeMount(() => {
      setIsLoadingRef(true);
      listarPaciente();
    });

    return {
      isLoadingRef,
      listaPacientesRef,
      selecionarPaciente,
    };
  },
});
</script>

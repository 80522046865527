
import {
  IonContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonNote,
} from "@ionic/vue";

import { defineComponent, onBeforeMount, ref } from "vue";

import { buscaPacientes } from "@/repository/Paciente/PacienteRepositoryV2";
import { PacienteResponse } from "@/repository/Paciente/PacienteData";

export default defineComponent({
  components: {
    IonContent,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
    IonNote,
  },
  emits: ["pacienteSelecionado", "fecharModal"],
  setup(props, { emit }) {
    const isLoadingRef = ref<boolean>(false);
    const setIsLoadingRef = (state: boolean) => (isLoadingRef.value = state);

    const listaPacientesRef = ref<PacienteResponse[]>([]);
    const setListaPacientesRef = (state: PacienteResponse[]) =>
      (listaPacientesRef.value = state);

    const listarPaciente = async () => {
      setIsLoadingRef(true);
      try {
        const response = await buscaPacientes();
        setListaPacientesRef(response.data["hydra:member"]);
        setIsLoadingRef(false);
      } catch (error) {
        setIsLoadingRef(false);
      }
    };

    const selecionarPaciente = (paciente: PacienteResponse) => {
      emit("pacienteSelecionado", paciente);
      emit("fecharModal");
    };

    onBeforeMount(() => {
      setIsLoadingRef(true);
      listarPaciente();
    });

    return {
      isLoadingRef,
      listaPacientesRef,
      selecionarPaciente,
    };
  },
});

<template>
  <ion-content>
    <ion-item-group v-if="!loading">
      <ion-item-divider>
        <ion-label>Alinhadores Superiores</ion-label>
      </ion-item-divider>
      <ion-item lines="none">
        <ion-label>
          <p>
            Qtd
          </p>
          <h2>
            {{ listaAlSuperiores.length }}
          </h2>
        </ion-label>
        <ion-label>
          <p>
            Valor
          </p>
          <h2>
            {{
              valorAlSuper.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h2>
        </ion-label>
      </ion-item>
      <ion-item-divider>
        <ion-label>Alinhadores Inferiores</ion-label>
      </ion-item-divider>
      <ion-item lines="none">
        <ion-label>
          <p>
            Qtd
          </p>
          <h2>
            {{ listaAlInferiores.length }}
          </h2>
        </ion-label>
        <ion-label>
          <p>
            Valor
          </p>
          <h2>
            {{
              valorAlInf.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h2>
        </ion-label>
      </ion-item>
      <ion-item-divider>
        <ion-label>Alinhador Attachment</ion-label>
      </ion-item-divider>
      <ion-item lines="none">
        <ion-label>
          <p>
            Valor
          </p>
          <h2>
            {{
              valorAlAttach.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h2>
        </ion-label>
      </ion-item>
      <ion-item-divider>
        <ion-label>Frete</ion-label>
      </ion-item-divider>
      <ion-radio-group>
        <ion-item>
          <ion-label>Retirar Na Empresa</ion-label>
          <ion-radio
            slot="start"
            :value="1"
            @click="calcFrete($event.target.value)"
          ></ion-radio>
        </ion-item>
        <ion-item v-if="habilitarMotoboy">
          <ion-label>Motoboy</ion-label>
          <ion-radio
            slot="start"
            :value="2"
            @click="calcFrete($event.target.value)"
          ></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>SEDEX</ion-label>
          <ion-radio
            slot="start"
            :value="4014"
            @click="calcFrete($event.target.value)"
          ></ion-radio>
        </ion-item>
        <ion-item lines="none">
          <ion-label>PAC</ion-label>
          <ion-radio
            slot="start"
            :value="4510"
            @click="calcFrete($event.target.value)"
          ></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-item-divider>
        <ion-label>Valores</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-label>
          <p>
            Alinhadores
          </p>
          <h2>
            {{
              (valorAlInf + valorAlSuper + valorAlAttach).toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )
            }}
          </h2>
        </ion-label>
        <ion-label>
          <p>
            Frete
          </p>
          <h2 v-if="valorFrete">
            {{
              valorFrete.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h2>
          <h2 v-if="!valorFrete">
            ------
          </h2>
        </ion-label>
        <ion-label>
          <p>
            Total
          </p>
          <h2>
            {{
              (
                valorAlInf +
                valorAlSuper +
                valorAlAttach +
                (valorFrete ? valorFrete : 0)
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </h2>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Data Vencimento</ion-label>
        <ion-datetime
          v-model="dataVencimento"
          display-format="DD/MM/YYYY"
          :min="minDate"
          :max="maxDate"
        ></ion-datetime>
      </ion-item>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size="6">
            <ion-button
              @click="validarPedido"
              expand="block"
              color="primary"
              shape="round"
              strong
            >
              Confirmar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item-group>
    <ion-item-group v-if="loading">
      <ion-item v-for="n in 10" :key="n">
        <ion-label>
          <ion-skeleton-text animated style="width: 80%; height: 2rem" />
        </ion-label>
      </ion-item>
    </ion-item-group>
    <ion-modal
      :is-open="isOpenModalConfirmadoPedidoRef"
      @onDidDismiss="setIsOpenModalConfirmadoPedidoRef(false)"
    >
      <BluealModal
        title="Confirmado"
        color="success"
        :closeModal="setIsOpenModalConfirmadoPedidoRef"
        routeOnClose="ListaProjetosAptosPedido"
      >
        <template v-slot:content>
          <ConfirmadoPedido :linkBoleto="linkBoleto" :nrPedido="nrPedido" />
        </template>
      </BluealModal>
    </ion-modal>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonModal,
  IonRadioGroup,
  IonRadio,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItemDivider,
  IonSkeletonText,
  modalController,
  IonItemGroup,
  loadingController,
  alertController,
} from "@ionic/vue";
import { defineComponent, onBeforeMount, ref } from "vue";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";
import ConfirmadoPedido from "@/views/pedidos-alinhadores-novo/add-ons/ConfirmadoPedido.vue";
import freteCorreiosV2Repository from "@/repository/freteCorreiosV2Repository";
import servicoRepositoryV2 from "@/repository/servicoV2Repository";
import pedidoAlinhadoresRepositoryV2 from "@/repository/pedidoAlinhadoresRepositoryV2";

export default defineComponent({
  components: {
    IonContent,
    IonDatetime,
    IonItem,
    IonLabel,
    IonModal,
    IonRadioGroup,
    IonRadio,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSkeletonText,
    IonItemDivider,
    IonItemGroup,
    BluealModal,
    ConfirmadoPedido,
  },
  props: {
    alinhadorAttachmentInferior: Boolean,
    alinhadorAttachmentSuperior: Boolean,
    enderecoEntrega: Object,
    projeto: Object,
    listaAlSuperiores: Array,
    listaAlInferiores: Array,
  },
  emits: ["atualizarLista"],
  setup(props) {
    const isOpenModalConfirmadoPedidoRef = ref(false);
    const setIsOpenModalConfirmadoPedidoRef = (state) => {
      isOpenModalConfirmadoPedidoRef.value = state;
    };

    const linkBoleto = ref();
    const setLinkBoleto = (state) => (linkBoleto.value = state);

    const nrPedido = ref();
    const setNrPedido = (state) => (nrPedido.value = state);

    const valorAlSuper = ref();
    const setValorAlSuper = (state) => (valorAlSuper.value = state);

    const valorAlInf = ref();
    const setValorAlInf = (state) => (valorAlInf.value = state);

    const qtdDiasProducao = ref();
    const setQtdDiasProducao = (state) => (qtdDiasProducao.value = state);

    const dataVencimento = ref();

    const dataEstimadaEntrega = ref();
    const setDataEstimadaEntrega = (state) =>
      (dataEstimadaEntrega.value = state);

    const valorServicoConfeccao = ref();
    const setValorServicoConfeccao = (state) =>
      (valorServicoConfeccao.value = state);

    const loading = ref();
    const setLoading = (state) => (loading.value = state);

    const freteTipoSelect = ref();
    const setFreteTipoSelect = (state) => (freteTipoSelect.value = state);

    const habilitarMotoboy = ref();
    const setHabilitarMotoboy = (state) => (habilitarMotoboy.value = state);

    const valorFrete = ref();
    const setValorFrete = (state) => (valorFrete.value = state);

    const maxDate = ref(new Date().toISOString());
    const setMaxDate = (state) => (maxDate.value = state);

    const minDate = new Date().toISOString();

    const valorAlAttach =
      (props.alinhadorAttachmentInferior ? 15 : 0) +
      (props.alinhadorAttachmentSuperior ? 15 : 0);

    const openModalError = async (title, message, color) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const buscarDadosServico = async () => {
      setLoading(true);
      try {
        const params = {
          codigoTipoServico: 101,
          indicadorValidade: 1,
        };

        const response = await servicoRepositoryV2.buscaServicos(params);
        setValorServicoConfeccao(response.data["hydra:member"][0].valorServico);

        const dateTemp = new Date();
        dateTemp.setDate(
          dateTemp.getDate() +
            response.data["hydra:member"][0].prazoPagamentoQtdDias
        );
        setMaxDate(dateTemp.toISOString());
        setQtdDiasProducao(response.data["hydra:member"][0].qtdDiasProducao);
        setValorAlSuper(
          props.listaAlSuperiores.length * valorServicoConfeccao.value
        );
        setValorAlInf(
          props.listaAlInferiores.length * valorServicoConfeccao.value
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        openModalError(
          "Erro",
          "Erro ao buscar informações dos serviços",
          "danger"
        );
      }
    };

    const calcFrete = async (codigoFrete) => {
      setFreteTipoSelect(codigoFrete);
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      loadingIonic.present();

      if (freteTipoSelect.value === 4014 || freteTipoSelect.value === 4510) {
        const cepDestino = props.enderecoEntrega.cep.replace(/[^\w\s]/gi, "");

        const body = {
          cepDestino: cepDestino,
          diasProducao: qtdDiasProducao.value.toString(),
          codigo: freteTipoSelect.value,
        };

        try {
          const response = await freteCorreiosV2Repository.calcularFreteCorreios(
            body
          );
          setValorFrete(response.data.valor);
          setDataEstimadaEntrega(new Date(response.data.dataEstimada));
          loadingIonic.dismiss();
        } catch (error) {
          loadingIonic.dismiss();
          openModalError("Erro", "Erro ao calcular frete", "danger");
        }
      } else if (freteTipoSelect.value === 2) {
        setValorFrete(20);
        const dateTemp = new Date();
        dateTemp.setDate(dateTemp.getDate() + 10);
        setDataEstimadaEntrega(dateTemp);
        loadingIonic.dismiss();
      } else if (freteTipoSelect.value === 1) {
        setValorFrete(0);
        const dateTemp = new Date();
        dateTemp.setDate(dateTemp.getDate() + 8);
        setDataEstimadaEntrega(dateTemp);
        loadingIonic.dismiss();
      }
    };

    const verficarCepDestino = (cepOrigem) => {
      const cepOrigemNumerico = parseInt(cepOrigem.replace(/[^\w\s]/gi, ""));
      if (
        (cepOrigemNumerico >= 13000001 && cepOrigemNumerico <= 13139999) ||
        (cepOrigemNumerico >= 13270001 && cepOrigemNumerico <= 13279999)
      ) {
        setHabilitarMotoboy(true);
      } else {
        setHabilitarMotoboy(false);
      }
    };

    const gerarNovaListaAlinhadores = (alSelecionados, listaAlinhadores) => {
      const novaListaAli = listaAlinhadores.filter((item) => {
        if (alSelecionados.includes(item.alinhador)) {
          item.pedido = true;
        }
        return item;
      });
      return novaListaAli;
    };

    const calcDiasVencimento = () => {
      const today = new Date();
      const expDay = Date.parse(dataVencimento.value);
      console.log("expDay", expDay);
      const qtdDias = Math.ceil(
        (expDay - today.getTime()) / (1000 * 3600 * 24)
      );
      return qtdDias;
    };

    const efetiverPedido = async () => {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      loadingIonic.present();

      try {
        const listaAlinhadoresSuperiorAux = gerarNovaListaAlinhadores(
          props.listaAlSuperiores,
          props.projeto.listaAlinhadoresSuperior
        );
        const listaAlinhadoresInferiorAux = gerarNovaListaAlinhadores(
          props.listaAlInferiores,
          props.projeto.listaAlinhadoresInferior
        );

        const qtdDiasVenc = calcDiasVencimento();

        const body = {
          listaAlinhadoresSuperior: props.listaAlSuperiores,
          listaAlinhadoresInferior: props.listaAlInferiores,
          projeto: props.projeto["@id"],
          enderecoEntrega: props.enderecoEntrega["@id"],
          freteTipo: `/frete_tipo/${freteTipoSelect.value}`,
          listaAlinhadoresSuperiorProjeto: listaAlinhadoresSuperiorAux,
          listaAlinhadoresInferiorProjeto: listaAlinhadoresInferiorAux,
          guiaAttachPedido: {
            attachmentSuperior: props.alinhadorAttachmentSuperior,
            attachmentInferior: props.alinhadorAttachmentInferior,
          },
          qtDiasPgt: qtdDiasVenc,
        };
        console.log("body", body);
        const response = await pedidoAlinhadoresRepositoryV2.criarPedidoAlinhadores(
          body
        );
        loadingIonic.dismiss();
        setLinkBoleto(response.data.cobranca.linkBoleto);
        setNrPedido(response.data.nrPedido);
        setIsOpenModalConfirmadoPedidoRef(true);
      } catch (error) {
        console.log(error);
        loadingIonic.dismiss();
        openModalError(
          "Erro ao gerar pedido",
          error.response.data["hydra:description"],
          "danger"
        );
      }
    };

    const validarPedido = async () => {
      if (!dataVencimento.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione a data de vencimento",
          buttons: ["Fechar"],
        });
        await alert.present();
      } else if (!freteTipoSelect.value) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione o tipo de frete",
          buttons: ["Fechar"],
        });
        await alert.present();
      } else {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Deseja confirmar o pedido?",
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
            },
            {
              text: "Confirmar",
              handler: () => {
                efetiverPedido();
              },
            },
          ],
        });
        await alert.present();
      }
    };

    onBeforeMount(() => {
      buscarDadosServico();
      verficarCepDestino(props.enderecoEntrega.cep);
    });

    return {
      isOpenModalConfirmadoPedidoRef,
      setIsOpenModalConfirmadoPedidoRef,
      linkBoleto,
      nrPedido,
      loading,
      valorAlSuper,
      valorAlInf,
      valorAlAttach,
      valorServicoConfeccao,
      freteTipoSelect,
      setFreteTipoSelect,
      calcFrete,
      habilitarMotoboy,
      valorFrete,
      minDate,
      maxDate,
      dataEstimadaEntrega,
      validarPedido,
      dataVencimento,
    };
  },
});
</script>


import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonTextarea,
  IonToggle,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosTratamentoPaciente",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonTextarea,
    IonToggle,
  },
  emits: ["dadosTratamentoPacienteAlteracao"],
  setup(props, { emit }) {
    const objetivoRef = ref<string | null>(null);
    const queixaRef = ref<string | null>(null);
    const implantesRef = ref<string | null>(null);
    const possuiImplantesRef = ref<boolean>(false);
    const setPossuiImplantesRef = (state: boolean) =>
      (possuiImplantesRef.value = state);

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        queixaPaciente: queixaRef.value,
        objetivoAlinhadores: objetivoRef.value,
        implantesDescricao: implantesRef.value,
      };

      emit("dadosTratamentoPacienteAlteracao", dadosTratametno);
    };

    return {
      objetivoRef,
      queixaRef,
      implantesRef,
      possuiImplantesRef,
      setPossuiImplantesRef,
      emitirDadosTratamento,
    };
  },
});

import Repository from "../Repository";
import { SolicitacaoProjetoListaResponse } from "./SolicitacaoProjetoData";

const resource = "/solicitacao_projeto_lista";

export const listarSolicitacoes = (page: number) => {
  return Repository.get<SolicitacaoProjetoListaResponse>(`${resource}`, {
    params: {
      page: page,
    },
  });
};

export const listarSolicitacoesFiltro = (estado: number, page: number) => {
  return Repository.get<SolicitacaoProjetoListaResponse>(`${resource}`, {
    params: {
      codigoEstadoSolicitacao: estado,
      page: page,
    },
  });
};

import store from "@/storage/storage";
import userRepo from "@/repository/user/UserRepository";

const getUser = async () => {
  const user = await store.get("@user");
  if (user) {
    return user;
  } else {
    throw new Error("Usuário não localizado no cache.");
  }
};
const setUser = async (userData: any) => {
  await store.set("@user", userData);
};

const getSessionToken = () => {
  const token = store.get("@sessionToken");
  if (token) {
    return token;
  } else {
    throw new Error("Token de sessão não localizado no cache.");
  }
};

const setSessionToken = async (token: string) => {
  await store.set("@sessionToken", token);
};

const getUserLogged = async () => {
  console.log("Obter usuario logado");
  try {
    const response = await userRepo.obterUsuarioLogado();
    await setUser(response.data);
    const user = await getUser();
    if (user) {
      return user;
    } else {
      throw new Error("Não foi possível obter usuário logado.");
    }
  } catch (error) {
    throw error.message;
  }
};

export { getUser, setUser, getSessionToken, setSessionToken, getUserLogged };

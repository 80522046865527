<template>
  <ion-content>
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="10">
          <ion-label>
            <h2>Pedido gerado com sucesso.</h2>
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="10">
          <ion-label> Número: {{ nrPedido }} </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12">
          <ion-button
            expand="block"
            color="primary"
            shape="round"
            strong
            @click="recuperarBoleto"
          >
            Clique aqui para visualizar o boleto.
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";

import { Browser } from "@capacitor/browser";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonContent,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  props: {
    linkBoleto: String,
    nrPedido: String,
  },
  setup(props) {
    const recuperarBoleto = async () => {
      await Browser.open({
        url: props.linkBoleto,
      });
    };

    return {
      recuperarBoleto,
    };
  },
});
</script>


import {
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ImagensPaciente",
  components: {
    IonList,
    IonItemDivider,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
  },
  emits: ["atualizarArquivoImagens", "atualizarTipoEnvioImagens"],
  setup(props, { emit }) {
    const uploadOption = ref("");

    const alteracaoArquivoImagens = (files: File[]) => {
      emit("atualizarArquivoImagens", files[0]);
    };

    const alteracaoTipoEnvioImagens = () => {
      emit("atualizarTipoEnvioImagens", uploadOption.value);
    };

    return {
      uploadOption,
      closeOutline,
      alteracaoArquivoImagens,
      alteracaoTipoEnvioImagens,
    };
  },
});

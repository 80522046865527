
import {
  IonApp,
  IonRouterOutlet,
  loadingController,
  toastController,
} from "@ionic/vue";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { createStore } from "@/storage/storage";
import Menu from "@/views/menu/Menu.vue";
import {
  getSessionToken,
  getUser,
} from "@/service/storage/SessionStorageService";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    Menu,
  },
  setup() {
    const navigation = useRouter();

    const checkLogin = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      try {
        loading.present();
        await getSessionToken();
        await getUser();
        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        const toast = await toastController.create({
          color: "danger",
          message: error.message,
          duration: 2000,
        });
        toast.present();
        navigation.push({ name: "Login" });
      }
    };

    onBeforeMount(async () => {
      console.log("APP MOUNT");
      try {
        await createStore();
      } catch (error) {
        console.log("initDbError: ", error);
      }
      checkLogin();
    });
  },
});

<template>
  <ion-page>
    <ion-content>
      <ion-grid v-if="!loading">
        <ion-item-group>
          <ion-item lines="none">
            <ion-label>
              <p>
                Código
              </p>
              <h2>
                {{ dadosSolicitacao.codigoSolicitacao }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Data</p>
              <h2>
                {{
                  new Date(
                    dadosSolicitacao.dataHoraSolicitacao.substring(0, 10)
                  ).toLocaleDateString("pt-BR", { timeZone: "UTC" })
                }}
              </h2>
            </ion-label>
          </ion-item>
        </ion-item-group>
        <ion-item-group>
          <ion-item-divider>
            <ion-label>
              Dados Paciente
            </ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>
              <p>
                Paciente
              </p>
              <h2>
                {{ dadosSolicitacao.paciente.nome }}
              </h2>
            </ion-label>
            <ion-label>
              <p>
                Dt. Nasc.
              </p>
              <h2>
                {{
                  dadosSolicitacao.paciente.dataNascimento
                    ? new Date(
                        dadosSolicitacao.paciente.dataNascimento
                      ).toLocaleDateString("pt-BR", { timeZone: "UTC" })
                    : ""
                }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>Plano Tratamento</p>
              <h3>{{ dadosSolicitacao.objetivoAlinhadores }}</h3>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>Queixa Paciente</p>
              <h3>{{ dadosSolicitacao.queixaPaciente }}</h3>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label>
              <p>Possui Implantes</p>
              <h3>
                {{
                  dadosSolicitacao.implantesDescricao
                    ? dadosSolicitacao.implantesDescricao
                    : "Não informado"
                }}
              </h3>
            </ion-label>
          </ion-item>
        </ion-item-group>
        <ion-item-group>
          <ion-item-divider>
            <ion-label>
              Dados Tratamento
            </ion-label>
          </ion-item-divider>
          <ion-item>
            <ion-label>
              <p>Mov. Arcada</p>
              <h2>
                {{
                  dadosSolicitacao.arcadaMovimentacao
                    ? dadosSolicitacao.arcadaMovimentacao
                    : "Não informado"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Desgaste Inter.</p>
              <h2>
                {{
                  dadosSolicitacao.desgasteInterProximal
                    ? dadosSolicitacao.desgasteInterProximal
                    : "Não informado"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Rel. Molar</p>
              <h2>
                {{
                  dadosSolicitacao.relacaoMolar
                    ? dadosSolicitacao.relacaoMolar
                    : "Não informado"
                }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>L. Méd. Superior</p>
              <h2>
                {{
                  dadosSolicitacao.linhaSuperiorMedia
                    ? dadosSolicitacao.linhaSuperiorMedia
                    : "N/A"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Direção</p>
              <h2>
                {{
                  dadosSolicitacao.linhaSuperiorDirecao
                    ? dadosSolicitacao.linhaSuperiorDirecao
                    : "N/A"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Movimento</p>
              <h2>
                {{
                  dadosSolicitacao.linhaSuperiorMediaMovimento
                    ? dadosSolicitacao.linhaSuperiorMediaMovimento
                    : "N/A"
                }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>L. Méd. Inferior</p>
              <h2>
                {{
                  dadosSolicitacao.linhaInferiorMedia
                    ? dadosSolicitacao.linhaInferiorMedia
                    : "N/A"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Direção</p>
              <h2>
                {{
                  dadosSolicitacao.linhaInferiorDirecao
                    ? dadosSolicitacao.linhaInferiorDirecao
                    : "N/A"
                }}
              </h2>
            </ion-label>
            <ion-label>
              <p>Movimento</p>
              <h2>
                {{
                  dadosSolicitacao.linhaInferiorMediaMovimento
                    ? dadosSolicitacao.linhaInferiorMediaMovimento
                    : "N/A"
                }}
              </h2>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>Dentes Não Movimentar</p>
              <h3>
                {{
                  dadosSolicitacao.movimentoDentes
                    ? dadosSolicitacao.movimentoDentes
                    : "Não informado"
                }}
              </h3>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>Attachment</p>
              <h3>
                {{
                  dadosSolicitacao.attachmentDentes
                    ? dadosSolicitacao.attachmentDentes
                    : "Não informado"
                }}
              </h3>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <p>Tempo Tratamento</p>
              <h3>
                {{
                  dadosSolicitacao.tempoTratamento
                    ? dadosSolicitacao.tempoTratamento
                    : "Não informado"
                }}
              </h3>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label>
              <p>Observações</p>
              <h3>
                {{
                  dadosSolicitacao.observacao
                    ? dadosSolicitacao.observacao
                    : "Não informado"
                }}
              </h3>
            </ion-label>
          </ion-item>
        </ion-item-group>
        <ion-item-divider>
          <ion-label>
            Planejamentos
          </ion-label>
        </ion-item-divider>
        <PlanejamentosSolicitacao
          :nrSlctProjeto="dadosSolicitacao.codigoSolicitacao"
          @projetoPendente="setNrProjetoPendenteRef($event)"
        />
        <ion-item-divider>
          <ion-label>
            Arquivos
          </ion-label>
        </ion-item-divider>
        <ion-grid v-if="links">
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadMaxilar"
                @click="openInBrowser(links.linkDownloadMaxilar)"
              >
                Maxila
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadMandibula"
                @click="openInBrowser(links.linkDownloadMandibula)"
              >
                Mandíbula
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoFrontal"
                @click="openInBrowser(links.linkDownloadFotoFrontal)"
              >
                Foto Frontal
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoLateral"
                @click="openInBrowser(links.linkDownloadFotoLateral)"
              >
                Foto Perfil
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoOclusaoSuperior"
                @click="openInBrowser(links.linkDownloadFotoOclusaoSuperior)"
              >
                Oclusão Superior
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoOclusaoInferior"
                @click="openInBrowser(links.linkDownloadFotoOclusaoInferior)"
              >
                Oclusão Inferior
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoRxPan"
                @click="openInBrowser(links.linkDownloadFotoRxPan)"
              >
                RX PAN
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoTeleRadiografia"
                @click="openInBrowser(links.linkDownloadFotoTeleRadiografia)"
              >
                TeleRadiografia
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoSorrindo"
                @click="openInBrowser(links.linkDownloadFotoSorrindo)"
              >
                Sorrindo
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoIntrabucalFrontal"
                @click="openInBrowser(links.linkDownloadFotoIntrabucalFrontal)"
              >
                Intrabucal Frontal
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoIntrabucalDireita"
                @click="openInBrowser(links.linkDownloadFotoIntrabucalDireita)"
              >
                Intrabucal Direita
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkDownloadFotoIntrabucalEsquerda"
                @click="openInBrowser(links.linkDownloadFotoIntrabucalEsquerda)"
              >
                Intrabucal Esquerda
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadArquivoUnicoImagemModelos"
                @click="
                  openInBrowser(links.linkDownloadArquivoUnicoImagemModelos)
                "
              >
                Arquivo Único
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadArquivoUnicoModelos"
                @click="openInBrowser(links.linkDownloadArquivoUnicoModelos)"
              >
                Arquivo Único Modelos
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadArquivoUnicoImagem"
                @click="openInBrowser(links.linkDownloadArquivoUnicoImagem)"
              >
                Arquivo Único Imagens
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-item-divider>
          <ion-label>
            Opções
          </ion-label>
        </ion-item-divider>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                expand="block"
                size="small"
                strong
                shape="round"
                v-if="
                  dadosSolicitacao.estado.codigoEstadoSolicitacao === 40 &&
                    nrProjetoPendenteRef
                "
                color="success"
                @click="alertAcept()"
              >
                <ion-icon :icon="checkmarkCircleOutline" />
                Aprovar
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                expand="block"
                size="small"
                strong
                shape="round"
                v-if="
                  dadosSolicitacao.estado.codigoEstadoSolicitacao === 40 &&
                    nrProjetoPendenteRef
                "
                color="danger"
                @click="alertRefuse()"
              >
                <ion-icon :icon="closeCircleOutline" />
                Recusar
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                expand="full"
                size="small"
                strong
                shape="round"
                color="medium"
                v-if="dadosSolicitacao.cobranca"
                @click="recuperarBoleto()"
              >
                <ion-icon :icon="barcodeOutline" />
                Boleto
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-grid>
      <ion-grid v-if="loading">
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 24px"
            ></ion-skeleton-text>
          </ion-col>
        </ion-row>
        <ion-row v-for="n in 6" :key="n" class="ion-justify-content-center">
          <ion-col>
            <ion-item>
              <ion-label>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 10px"
                      ></ion-skeleton-text>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 20px"
                      ></ion-skeleton-text>
                    </ion-col>
                    <ion-col>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 10px"
                      ></ion-skeleton-text>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 20px"
                      ></ion-skeleton-text>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 10px"
                      ></ion-skeleton-text>
                      <ion-skeleton-text
                        animated
                        style="width: 80%; height: 20px"
                      ></ion-skeleton-text>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonItem,
  IonLabel,
  IonButton,
  IonCol,
  IonRow,
  IonSkeletonText,
  IonFab,
  IonFabList,
  IonIcon,
  IonItemDivider,
  IonItemGroup,
  loadingController,
  alertController,
} from "@ionic/vue";

import {
  closeCircleOutline,
  menuOutline,
  barcodeOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { Browser } from "@capacitor/browser";

import { ref } from "@vue/reactivity";

import solicitacaoProjetoV2Repository from "@/repository/solicitacaoProjetoV2Repository";
import solicitacaoProjetoArquivosV2Repository from "@/repository/solicitacaoProjetoArquivosV2Repository";
import cobrancaRepository from "@/repository/cobrancaRepository";
import PlanejamentosSolicitacao from "./dados-projetos-solicitacao/PlanejamentosSolicitacao.vue";
import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";

export default {
  name: "ModalConsultarSolicitacaoProjeto",
  props: {
    codigoSolicitacao: String,
  },
  emits: ["atualizarLista", "closeModal"],
  setup(props, { emit }) {
    const refuseTextRef = ref("");
    const setRefuseTextRef = (state) => (refuseTextRef.value = state);

    const nrProjetoPendenteRef = ref(null);
    const setNrProjetoPendenteRef = (state) => {
      nrProjetoPendenteRef.value = state;
    };

    const emitAtualizarListar = () => {
      emit("atualizarLista");
    };

    const openInBrowser = async (url) => {
      console.log("url", url);
      await Browser.open({ url: url });
    };

    const alertSuccess = async (header, message) => {
      const alert = await alertController.create({
        header: `${header}`,
        message: `${message}`,
        buttons: [
          {
            text: "OK",
            handler: () => {
              emitAtualizarListar();
            },
          },
        ],
      });
      return alert.present();
    };

    const acceptPoject = async () => {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      loadingIonic.present();

      try {
        const body = {
          operacao: "A",
        };
        await projetoRepositoryV2.alterarProjeto(
          nrProjetoPendenteRef.value,
          body
        );
        loadingIonic.dismiss();
        alertSuccess("Sucesso", "Projeto Aceito!!!");
      } catch (e) {
        loadingIonic.dismiss();
        console.error(e);
      }
    };

    const refusePoject = async () => {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      loadingIonic.present();

      try {
        const body = {
          operacao: "R",
          textoRecusa: refuseTextRef.value,
        };
        await projetoRepositoryV2.alterarProjeto(
          nrProjetoPendenteRef.value,
          body
        );
        loadingIonic.dismiss();
        alertSuccess("Sucesso", "Projeto Recusado!!!");
      } catch (e) {
        loadingIonic.dismiss();
        console.error(e);
      }
    };

    const alertAcept = async () => {
      const alert = await alertController.create({
        header: "Deseja Aceitar?",
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Confirmar",
            handler: () => {
              acceptPoject();
            },
          },
        ],
      });
      return alert.present();
    };

    const alertRefuse = async () => {
      const alert = await alertController.create({
        header: "Deseja Recusar?",
        inputs: [
          {
            name: "text-refuse",
            id: "text-refuse-id",
            type: "textarea",
            value: "",
            placeholder: "Informe motivo",
            attributes: {
              minLength: 5,
              maxlength: 255,
              onChange: (event) => setRefuseTextRef(event.target.value),
            },
          },
        ],
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "Confirmar",
            handler: () => {
              refusePoject();
            },
          },
        ],
      });
      return alert.present();
    };

    return {
      openInBrowser,
      alertAcept,
      alertRefuse,
      nrProjetoPendenteRef,
      setNrProjetoPendenteRef,
    };
  },
  data() {
    return {
      dadosSolicitacao: null,
      loading: false,
      closeCircleOutline: closeCircleOutline,
      menuOutline: menuOutline,
      barcodeOutline: barcodeOutline,
      checkmarkCircleOutline: checkmarkCircleOutline,
      links: null,
    };
  },
  components: {
    IonGrid,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonIcon,
    IonItemDivider,
    IonItemGroup,
    IonButton,
    PlanejamentosSolicitacao,
  },
  beforeMount() {
    this.buscarSolicitacao(this.codigoSolicitacao);
  },
  methods: {
    reiniciar() {
      this.buscarSolicitacao(this.codigoSolicitacao);
      this.$emit("atualizacaoProjeto");
    },
    async buscarSolicitacao(nrSlct) {
      this.dadosSolicitacao = null;
      this.loading = true;
      try {
        const { data } = await solicitacaoProjetoV2Repository.buscarSolicitacao(
          nrSlct
        );
        this.loading = false;
        this.dadosSolicitacao = data;
        this.gerarLinksArquivos();
      } catch (error) {
        this.loading = false;
        console.error("error", error);
      }
    },
    async gerarLinksArquivos() {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      loadingIonic.present();
      try {
        const body = {
          solicitacaoProjeto: this.dadosSolicitacao["@id"],
          operacao: "D",
        };
        const response = await solicitacaoProjetoArquivosV2Repository.gerarLinkArquivos(
          body
        );
        this.links = response.data;
        loadingIonic.dismiss();
      } catch (e) {
        loadingIonic.dismiss();
      }
    },
    async recuperarBoleto() {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      await loadingIonic.present();
      try {
        const response = await cobrancaRepository.buscarCobrancaCodigo(
          this.dadosSolicitacao.cobranca.codigo
        );
        loadingIonic.dismiss();
        if (response.data.linkBoleto !== null) {
          window.open(response.data.linkBoleto, "_blank");
        } else {
          console.log("sem boleto");
        }
      } catch (error) {
        loadingIonic.dismiss();
        console.error("error", error);
      }
    },
  },
};
</script>

<template>
  <ion-content class="ion-padding">
    <ion-list v-if="!loading">
      <ion-item-group
        v-for="item in listaEnderecos"
        :key="item.codigo"
        @click="selecionarEndereco(item)"
      >
        <ion-item lines="none">
          <ion-label>
            <p>Logradouro / Número</p>
            <h2>{{ item.logradouro + ", " + item.numero }}</h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>Complemento</p>
            <h2>{{ item.complemento }}</h2>
          </ion-label>
          <ion-label>
            <p>Bairro</p>
            <h2>{{ item.bairro }}</h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>Município</p>
            <h2>{{ item.municipio }}</h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>UF</p>
            <h2>{{ item.uf }}</h2>
          </ion-label>
          <ion-label>
            <p>CEP</p>
            <h2>{{ item.cep }}</h2>
          </ion-label>
        </ion-item>
      </ion-item-group>
    </ion-list>
    <ion-list v-if="loading">
      <ion-item-group v-for="i in 10" :key="i">
        <ion-item v-for="n in 3" :key="n" lines="none">
          <ion-label>
            <ion-skeleton-text animated style="width: 80%; height: 1.7rem">
            </ion-skeleton-text>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <ion-skeleton-text animated style="width: 80%; height: 1.7rem">
            </ion-skeleton-text>
          </ion-label>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSkeletonText,
} from "@ionic/vue";

import { defineComponent, onBeforeMount, ref } from "vue";

import enderecoV2Repository from "@/repository/enderecoV2Repository";

export default defineComponent({
  components: {
    IonContent,
    IonItemGroup,
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
  },
  emits: ["enderecoSelecionado"],
  setup(props, { emit }) {
    const loading = ref(false);
    const setLoading = (state) => (loading.value = state);

    const listaEnderecos = ref([]);
    const setListaEnderecos = (state) => (listaEnderecos.value = state);

    const listarEnderecos = async () => {
      setLoading(true);
      try {
        const params = {
          estadoEndereco: 10,
        };
        const response = await enderecoV2Repository.buscaEnderecosAtivosUsuario(
          params
        );
        setListaEnderecos(response.data["hydra:member"]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const selecionarEndereco = (endereco) => {
      emit("enderecoSelecionado", endereco);
    };

    onBeforeMount(() => {
      listarEnderecos();
    });

    return {
      loading,
      listaEnderecos,
      selecionarEndereco,
    };
  },
});
</script>


import {
  IonContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonInput,
  IonDatetime,
  modalController,
  loadingController,
  alertController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";

import { incluirPaciente } from "@/repository/Paciente/PacienteRepositoryV2";
import { IncluirPacienteRequest } from "@/repository/Paciente/PacienteData";

export default defineComponent({
  components: {
    IonContent,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonList,
    IonInput,
    IonDatetime,
  },
  emits: ["pacienteSelecionado", "fecharModal"],
  setup(props, { emit }) {
    const nomePacienteRef = ref<string>("");

    const dtNascimentoPacienteRef = ref<string>("");

    const minDate = new Date("1900-01-01").toISOString();
    const maxDate = new Date().toISOString();

    const openModalError = async (
      title: string,
      message: string,
      color: string
    ) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const adicionarPaciente = async () => {
      if (dtNascimentoPacienteRef.value.length === 0) {
        const alert = await alertController.create({
          header: "Atenção",
          message: "Selecione a data de nascimento do paciente",
          buttons: ["Fechar"],
        });
        await alert.present();
      } else {
        const loadingIonic = await loadingController.create({
          message: "Aguarde...",
          translucent: true,
          backdropDismiss: false,
        });
        await loadingIonic.present();

        try {
          const body: IncluirPacienteRequest = {
            nome: nomePacienteRef.value,
            dataNascimento: dtNascimentoPacienteRef.value,
          };
          const response = await incluirPaciente(body);
          emit("pacienteSelecionado", response.data);
          emit("fecharModal");
          loadingIonic.dismiss();
        } catch (error) {
          loadingIonic.dismiss();
          openModalError("Erro", "Erro ao incluir paciente", "danger");
        }
      }
    };

    return {
      nomePacienteRef,
      dtNascimentoPacienteRef,
      adicionarPaciente,
      minDate,
      maxDate,
    };
  },
});

<template>
  <ion-content class="ion-padding">
    <ion-item-group>
      <ion-item v-for="item in listaAlinhadores" :key="item" lines="inset">
        <ion-label>
          <h3>{{item}}</h3>
        </ion-label>
      </ion-item>
    </ion-item-group>
  </ion-content>
</template>

<script>
import { IonContent, IonItemGroup, IonItem, IonLabel } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ListaAlinhadoresPedido",
  props: {
    listaAlinhadores: Array,
  },
  components: { IonContent, IonItemGroup, IonItem, IonLabel },
});
</script>

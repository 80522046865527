<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Imagens Paciente
      </ion-label>
    </ion-item-divider>
    <ion-item-group>
      <ion-item>
        <ion-label>Tipo de Envio</ion-label>
        <ion-select
          v-model="uploadOption"
          :value="uploadOption"
          @ionChange="alteracaoTipoEnvioImagens()"
          placeholder="Selecione"
        >
          <ion-select-option value="individuais">Individual</ion-select-option>
          <ion-select-option value="unico">Único</ion-select-option>
          <ion-select-option value="naoEnviar">Não Enviar</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-item-group>
    <ion-item-group v-if="uploadOption === 'unico'">
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Arquivo Único (zip, rar)</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          @change="alteracaoArquivoImagens($event.target.files)"
          name="arquivo"
          color="primary"
          type="file"
          accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, application/x-rar-compressed, application/octet-stream"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group v-if="uploadOption === 'individuais'">
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Face Frontal</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Face Frontal Sorrindo</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Face Perfil</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Intrabucal Frontal</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Intrabucal Direita</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Intrabucal Esquerda</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Oclusal Superior</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Oclusal Inferior</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>RX PAN</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label ref="arquivo"><p>Teleradiografia</p></ion-label>
      </ion-item>
      <ion-item lines="full">
        <ion-input
          name="arquivo"
          color="primary"
          type="file"
          accept="image/png, image/jpeg"
        ></ion-input>
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ImagensPaciente",
  components: {
    IonList,
    IonItemDivider,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
  },
  emits: ["atualizarArquivoImagens", "atualizarTipoEnvioImagens"],
  setup(props, { emit }) {
    const uploadOption = ref("");

    const alteracaoArquivoImagens = (files: File[]) => {
      emit("atualizarArquivoImagens", files[0]);
    };

    const alteracaoTipoEnvioImagens = () => {
      emit("atualizarTipoEnvioImagens", uploadOption.value);
    };

    return {
      uploadOption,
      closeOutline,
      alteracaoArquivoImagens,
      alteracaoTipoEnvioImagens,
    };
  },
});
</script>

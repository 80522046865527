<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Informações Adicionais
      </ion-label>
    </ion-item-divider>
    <ion-item-group>
      <ion-item>
        <ion-label><h3>Dentes que não podem ser movimentados</h3></ion-label>
        <ion-toggle
          @ionChange="
            setPossuiDentesNaoMovimentarRef(!possuiDentesNaoMovimentarRef)
          "
          :value="possuiDentesNaoMovimentarRef"
          :checked="possuiDentesNaoMovimentarRef"
          color="primary"
        />
      </ion-item>
      <ion-item v-if="possuiDentesNaoMovimentarRef" lines="none">
        <ion-label position="floating"><h3>Descreva</h3></ion-label>
        <ion-textarea
          v-model="dentesNaoMovimentarRef"
          :required="possuiDentesNaoMovimentarRef"
          :minlength="3"
          @ionChange="emitirDadosTratamento()"
          placeholder="Ex. dentes 15, 16"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item>
        <ion-label><h3>Attachment Dentes</h3></ion-label>
        <ion-toggle
          @ionChange="setPossuiAttachmentRef(!possuiAttachmentRef)"
          :value="possuiAttachmentRef"
          :checked="possuiAttachmentRef"
          color="primary"
        />
      </ion-item>
      <ion-item v-if="possuiAttachmentRef" lines="none">
        <ion-label position="floating"><h3>Descreva</h3></ion-label>
        <ion-textarea
          v-model="attachmentRef"
          :required="possuiAttachmentRef"
          :minlength="3"
          @ionChange="emitirDadosTratamento()"
          placeholder="Ex. dentes 15, 16"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item>
        <ion-label><h3>Data Limite para término?</h3></ion-label>
        <ion-toggle
          @ionChange="setPossuiTempoTratamentoRef(!possuiTempoTratamentoRef)"
          :value="possuiTempoTratamentoRef"
          :checked="possuiTempoTratamentoRef"
          color="primary"
        />
      </ion-item>
      <ion-item v-if="possuiTempoTratamentoRef" lines="none">
        <ion-label position="floating"><h3>Descreva</h3></ion-label>
        <ion-textarea
          v-model="tempoTratamentoRef"
          :minlength="3"
          :required="possuiTempoTratamentoRef"
          @ionChange="emitirDadosTratamento()"
          placeholder="Ex. 6 meses"
        />
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item>
        <ion-label><h3>Info. Adicionais</h3></ion-label>
        <ion-toggle
          @ionChange="setPossuiInfoAdicionaisRef(!possuiInfoAdicionaisRef)"
          :value="possuiInfoAdicionaisRef"
          :checked="possuiInfoAdicionaisRef"
          color="primary"
        />
      </ion-item>
      <ion-item v-if="possuiInfoAdicionaisRef" lines="none">
        <ion-label position="floating"><h3>Descreva</h3></ion-label>
        <ion-textarea
          v-model="infoAdicionaisRef"
          :minlength="3"
          :required="possuiInfoAdicionaisRef"
          @ionChange="emitirDadosTratamento()"
          placeholder="Ex. dentes 15, 16"
        />
      </ion-item>
    </ion-item-group>
  </ion-list>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonToggle,
  IonTextarea,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosAdiconaisTratamento",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonToggle,
    IonTextarea,
  },
  emits: ["dadosAdicionaisTratamentoAlteracao"],
  setup(props, { emit }) {
    const dentesNaoMovimentarRef = ref<string | null>(null);
    const possuiDentesNaoMovimentarRef = ref<boolean>(false);
    const setPossuiDentesNaoMovimentarRef = (state: boolean) =>
      (possuiDentesNaoMovimentarRef.value = state);

    const attachmentRef = ref<string | null>(null);
    const possuiAttachmentRef = ref<boolean>(false);
    const setPossuiAttachmentRef = (state: boolean) =>
      (possuiAttachmentRef.value = state);

    const tempoTratamentoRef = ref<string | null>(null);
    const possuiTempoTratamentoRef = ref<boolean>(false);
    const setPossuiTempoTratamentoRef = (state: boolean) =>
      (possuiTempoTratamentoRef.value = state);

    const infoAdicionaisRef = ref<string | null>(null);
    const possuiInfoAdicionaisRef = ref<boolean>(false);
    const setPossuiInfoAdicionaisRef = (state: boolean) =>
      (possuiInfoAdicionaisRef.value = state);

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        movimentoDentes: dentesNaoMovimentarRef.value,
        observacao: infoAdicionaisRef.value,
        tempoTratamento: tempoTratamentoRef.value,
        attachmentDentes: attachmentRef.value,
      };

      emit("dadosAdicionaisTratamentoAlteracao", dadosTratametno);
    };

    return {
      dentesNaoMovimentarRef,
      possuiDentesNaoMovimentarRef,
      setPossuiDentesNaoMovimentarRef,
      attachmentRef,
      possuiAttachmentRef,
      setPossuiAttachmentRef,
      tempoTratamentoRef,
      possuiTempoTratamentoRef,
      setPossuiTempoTratamentoRef,
      infoAdicionaisRef,
      possuiInfoAdicionaisRef,
      setPossuiInfoAdicionaisRef,
      emitirDadosTratamento,
    };
  },
});
</script>

<template>
  <form @submit.prevent="lostPassword()">
    <ion-grid v-if="!isRedefinedPassword">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input
              required
              type="email"
              v-model="emailLostPassword"
              minlength="5"
              maxlength="255"
            />
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-margin-top">
        <ion-col size="10">
          <ion-button
            expand="block"
            strong
            shape="round"
            color="primary"
            type="submit"
          >
            <ion-icon slot="start" :icon="lockOpen" />
            Recuperar senha
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid v-if="isRedefinedPassword">
      <ion-row class="ion-justify-content-center">
        <ion-col>
          <p>
            Uma mensagem foi enviada para o email indicado com as instruções
            para recuperar sua senha.
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import {
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonLabel,
  IonInput,
  IonItem,
  IonIcon,
  modalController,
  loadingController,
} from "@ionic/vue";

import { lockOpen } from "ionicons/icons";

import { UserNewPasswordRequest } from "@/repository/user/UserInterfaces";
import UserRepository from "@/repository/user/UserRepository";
import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";

const menu = defineComponent({
  setup() {
    const isRedefinedPassword = ref<boolean>(false);
    const setIsRedefinedPassword = (state: boolean) =>
      (isRedefinedPassword.value = state);

    const emailLostPassword = ref<string>("");

    const openModal = async (title: string, message: string, color: string) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: "danger",
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const lostPassword = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      console.log("email", emailLostPassword.value);
      try {
        loading.present();
        const payload: UserNewPasswordRequest = {
          email: emailLostPassword.value,
        };

        await UserRepository.lostPassword(payload);
        loading.dismiss();
        setIsRedefinedPassword(true);
      } catch (error) {
        loading.dismiss();
        openModal(
          "Erro ao efetuar login",
          `${error.response.data["hydra:description"]}`,
          "danger"
        );
      }
    };

    return {
      emailLostPassword,
      lostPassword,
      lockOpen,
      isRedefinedPassword,
    };
  },
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
  },
});

export default menu;
</script>

<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end">
          <ion-icon
            size="large"
            :icon="closeCircleOutline"
            @click="returnHome()"
          ></ion-icon>
        </ion-buttons>
        <ion-title>Pedidos Alinhadores</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list v-if="!loading">
        <ion-list-header lines="full">
          <ion-label>
            <h2>
              {{ `${textTitleList} ` }}
              <ion-badge>
                {{ qtdListaPedidosAlinhadores }}
              </ion-badge>
            </h2>
          </ion-label>
          <ion-button
            strong
            size="small"
            color="primary"
            @click="setIsOpenFilterPopOverRef(false);setIsOpenFilterPopOverRef(true, $event)"
            shape="round"
            fill="outline"
          >
            ALTERAR FILTRO
          </ion-button>
          <ion-popover
            :is-open="isOpenFilterPopOverRef"
            :event="eventPopOver"
            :translucent="true"
            :backdrop-dismiss="false"
          >
            <ion-content>
              <ion-item-group>
                <ion-item
                  @click="
                    textTitleList = 'Em Produção';
                    listarPedidosAlinhadores(1, 10);
                    setIsOpenFilterPopOverRef(false, eventPopOver);
                  "
                >
                  <ion-label>
                    Em Produção
                  </ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  @click="
                    textTitleList = 'Finalizados';
                    listarPedidosAlinhadores(1, 30);
                    setIsOpenFilterPopOverRef(false, eventPopOver);
                  "
                >
                  <ion-label>
                    Finalizados
                  </ion-label>
                </ion-item>
              </ion-item-group>
            </ion-content>
          </ion-popover>
        </ion-list-header>
        <ion-item
          v-for="item in listaPedidosAlinhadores"
          :key="item.nrPedido"
          @click="
            nrPedidoAlinhadoresSelected = item.nrPedido;
            nomePacienteSelected = item.nomePaciente;
            setOpenModalConsultaRef(true);
          "
        >
          <ion-label>
            <p>Número Pedido</p>
            <h2>{{ item.nrPedido }}</h2>
          </ion-label>
          <ion-label>
            <p>Paciente</p>
            <h2>{{ item.nomePaciente }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-if="loading">
        <ion-list-header lines="full">
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1rem"
            ></ion-skeleton-text>
          </ion-label>
        </ion-list-header>
        <ion-item v-for="i in 10" :key="i">
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1.7rem"
            ></ion-skeleton-text>
          </ion-label>
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 1.7rem"
            ></ion-skeleton-text>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-modal
        :is-open="isOpenModalConsultaRef"
        @onDidDismiss="setOpenModalConsultaRef(false)"
      >
        <BluealModal
          title="Pedido Alinhadores"
          color="tertiary"
          :closeModal="setOpenModalConsultaRef"
        >
          <template v-slot:content>
            <ModalConsultarPedidoAlinhadores
              :nrPedidoAlinhadores="nrPedidoAlinhadoresSelected"
              :nomePaciente="nomePacienteSelected"
            />
          </template>
        </BluealModal>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonIcon,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonBadge,
  IonButtons,
  IonButton,
  IonPopover,
  IonModal,
} from "@ionic/vue";

import {
  filterOutline,
  checkmarkCircleOutline,
  alertCircleOutline,
  closeCircleOutline,
  documentTextOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import ModalConsultarPedidoAlinhadores from "@/views/pedidos-alinhadores-gerenciar/consulta-pedidos-alinhadores/ModalConsultarPedidoAlinhadores.vue";
import pedidoAlinhadoresListaRepositoryV2 from "@/repository/pedidoAlinhadoresListaRepositoryV2";

export default defineComponent({
  name: "GerenciarPedidosAlinhadores",
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonIcon,
    IonList,
    IonListHeader,
    IonSkeletonText,
    IonBadge,
    IonButtons,
    IonButton,
    IonPopover,
    IonModal,
    BluealModal,
    ModalConsultarPedidoAlinhadores,
  },
  data() {
    return {
      loading: true,
      nrPedidoAlinhadoresSelected: "",
      nomePacienteSelected: "",
      qtdPaginasTodas: 1,
      listaPedidosAlinhadores: [],
      qtdListaPedidosAlinhadores: 0,
      textTitleList: "Em Produção",
      filterOutline: filterOutline,
      checkmarkCircleOutline: checkmarkCircleOutline,
      alertCircleOutline: alertCircleOutline,
      closeCircleOutline: closeCircleOutline,
      documentTextOutline: documentTextOutline,
    };
  },
  setup() {
    const navigate = useRouter();
    const isOpenModalConsultaRef = ref(false);

    const eventPopOver = ref();
    const isOpenFilterPopOverRef = ref(false);
    const setIsOpenFilterPopOverRef = (state, event) => {
      eventPopOver.value = event;
      isOpenFilterPopOverRef.value = state;
    };

    const setOpenModalConsultaRef = (state) =>
      (isOpenModalConsultaRef.value = state);

    const returnHome = () => {
      navigate.push({ name: "Home" });
    };

    return {
      returnHome,
      isOpenModalConsultaRef,
      setOpenModalConsultaRef,
      eventPopOver,
      isOpenFilterPopOverRef,
      setIsOpenFilterPopOverRef,
    };
  },
  ionViewWillEnter() {
    this.listarPedidosAlinhadores(1, 10);
  },
  methods: {
    async listarPedidosAlinhadores(page, estado) {
      this.loading = true;
      try {
        const response = await pedidoAlinhadoresListaRepositoryV2.listarPedidoAlinhadores(
          page,
          estado
        );
        this.listaPedidosAlinhadores = response.data["hydra:member"];
        this.qtdListaPedidosAlinhadores = response.data["hydra:totalItems"];
        this.loading = false;
        this.qtdPaginasTodas = page;
      } catch (e) {
        this.loading = false;
      }
    },
    reset() {
      this.textTitleList = "Em Produção";
      this.listarPedidosAlinhadores(1, 10);
    },
  },
});
</script>

<!-- adicione o atributo “scoped” para limitar o CSS apenas a este componente -->
<style scoped>
.painel-meus-planejamentos {
  padding: 1rem;
}
</style>

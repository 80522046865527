import Repository from "@/repository/Repository";
import { AxiosResponse } from "axios";
import {
  AlterarSolicitacaoProjetoRequest,
  AlterarSolicitacaoProjetoResponse,
  GerarSolicitacaoProjetoRequest,
  GerarSolicitacaoProjetoResponse,
} from "./SolicitacaoProjetoData";

const resource = "/solicitacao_projeto";

export const gerarSolicitacao = (request: GerarSolicitacaoProjetoRequest) => {
  return Repository.post<GerarSolicitacaoProjetoResponse>(
    `${resource}`,
    request
  );
};

export const alterarSolicitacao = (
  numeroSolicitacao: string,
  request: AlterarSolicitacaoProjetoRequest
) => {
  return Repository.put<AlterarSolicitacaoProjetoResponse>(
    `${resource}/${numeroSolicitacao}`,
    request
  );
};

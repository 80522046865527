
import store from "@/storage/storage";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonThumbnail,
  IonItem,
  IonLabel,
  IonImg,
  IonIcon,
} from "@ionic/vue";

import { image, logOut } from "ionicons/icons";

import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import anonUser from "@/assets/anonymous-user.png";

const config = defineComponent({
  name: "Perfil",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonThumbnail,
    IonItem,
    IonLabel,
    IonImg,
    IonIcon,
  },
  setup() {
    const modoEscuroRef = ref<boolean>();
    const setModoEscuroRef = (state: boolean) => {
      modoEscuroRef.value = state;
    };

    const userFirstNameRef = ref<string>();
    const setUserFirstNameRef = (state: string) =>
      (userFirstNameRef.value = state);

    const userLastNameRef = ref<string>();
    const setUserLastNameRef = (state: string) =>
      (userLastNameRef.value = state);

    const userEmailRef = ref<string>();
    const setUserEmailRef = (state: string) => (userEmailRef.value = state);

    const imageUrlRef = ref<string>();
    const setImageUrlRef = (state: string) => (imageUrlRef.value = state);

    const router = useRouter();

    const setUserData = async () => {
      const user = await store.get("@user");
      setUserFirstNameRef(user.nome.split(" ")[0]);
      setUserLastNameRef(user.nome.split(" ").slice(-1)[0]);
      setUserEmailRef(user.email);
      setImageUrlRef(user.linkImagem);
    };

    const navigation = (routeName: string) => {
      router.replace({ name: routeName });
    };

    onBeforeMount(() => {
      console.log("entrou perfil");
      setUserData();
    });

    return {
      navigation,
      userFirstNameRef,
      userLastNameRef,
      userEmailRef,
      imageUrlRef,
      setImageUrlRef,
      setModoEscuroRef,
      modoEscuroRef,
      anonUser,
      image,
      logOut,
    };
  },
});

export default config;

import Repository from './Repository';

const resource = '/frete_correios';

export default{
    calcularFreteCorreios(body){
        return Repository.post(
            `${resource}`,
            body
        );
    },
}

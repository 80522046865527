import Repository from "@/repository/Repository";
import { AxiosResponse } from "axios";
import {
  GerarSolicitacaoProjetoArquivoRequest,
  GerarSolicitacaoProjetoArquivoResponse,
} from "./SolicitacaoProjetoArqivoData";

const resource = "/solicitacao_projeto_arquivos";

export const gerarLinkArquivos = (
  request: GerarSolicitacaoProjetoArquivoRequest
) => {
  return Repository.post<GerarSolicitacaoProjetoArquivoResponse>(
    `${resource}`,
    request
  );
};

import Repository from './Repository';

const resource = '/projeto_arquivos';
export default{
    gerarLinkArquivos(body){
        return Repository.post(
            `${resource}`,
            body
        );
    }
}

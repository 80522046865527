<template>
  <ion-menu side="start" menu-id="main" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-button @click="closeMenu()">
            <ion-icon :icon="closeCircle" />
          </ion-button>
        </ion-buttons>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item @click="openPlaneMenu">Planejamentos</ion-item>
        <ion-item @click="openAlinhadoresMenu">Alinhadores</ion-item>
        <!-- <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item> -->
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-menu side="start" menu-id="plane" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-button @click="openMainMenu()">
            <ion-icon :icon="arrowBackCircle" />
          </ion-button>
        </ion-buttons>
        <ion-title>
          <span>Planejamentos</span>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item @click="navigate('NovaSolicitacaoProjeto')">Novo</ion-item>
        <ion-item @click="navigate('ConsultaSolicitacaoProjeto')"
          >Consultar</ion-item
        >
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-menu side="start" menu-id="alinhadores" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-button @click="openMainMenu()">
            <ion-icon :icon="arrowBackCircle" />
          </ion-button>
        </ion-buttons>
        <ion-title>
          <span>Alinhadores</span>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item @click="navigate('ListaProjetosAptosPedido')"
          >Novo Pedido</ion-item
        >
        <ion-item @click="navigate('GerenciarPedidosAlinhadores')"
          >Consultar Pedido</ion-item
        >
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  menuController,
} from "@ionic/vue";
import { arrowBackCircle, closeCircle } from "ionicons/icons";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const navigation = useRouter();

    const openMainMenu = () => {
      menuController.enable(true, "main");
      menuController.open("main");
    };
    const openPlaneMenu = () => {
      menuController.enable(true, "plane");
      menuController.open("plane");
    };
    const openAlinhadoresMenu = () => {
      menuController.enable(true, "alinhadores");
      menuController.open("alinhadores");
    };
    const closeMenu = () => {
      menuController.close();
    };

    const navigate = (routeName) => {
      closeMenu();
      navigation.push({ name: routeName });
    };

    return {
      openMainMenu,
      openPlaneMenu,
      openAlinhadoresMenu,
      closeMenu,
      navigate,
      arrowBackCircle,
      closeCircle,
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
  },
});
</script>

<template>
  <ion-grid v-if="listaObservacoes">
    <ion-list v-for="item in listaObservacoes" :key="item.nrSeqImagem">
      <ion-item @click="abrirUrlArquivo(item.nrSeqImagem)">
        <ion-label class="ion-text-wrap">
          <p>Clique para ver a imagem</p>
          <h3>
            {{ item.observacao }}
          </h3>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-grid>
  <ion-grid v-if="!listaObservacoes || listaObservacoes.length === 0">
    <ion-text color="medium">
      <p>Sem observações</p>
    </ion-text>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonList,
  IonText,
  IonItem,
  IonLabel,
  loadingController,
  modalController,
} from "@ionic/vue";

import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert";
import projetoArquivosRepositoryV2 from "@/repository/projetoArquivosRepositoryV2";

export default {
  name: "ObservacoesPlanejamentoSolicitacao",
  props: {
    listaObservacoes: Object,
    nrProjeto: String,
  },
  components: {
    IonGrid,
    IonList,
    IonText,
    IonItem,
    IonLabel,
  },
  data() {
    return {
      listaProjetos: null,
      dadosPlanejamentoSelected: null,
      loading: false,
    };
  },
  methods: {
    async openModal(title, message, color) {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    },
    async abrirUrlArquivo(nrSqlImage) {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      loading.present();

      let body = {
        projeto: `/projeto/${this.nrProjeto}`,
        operacao: "D",
      };

      if (nrSqlImage !== null) {
        body = {
          ...body,
          nrSeqImgObs: nrSqlImage,
        };
      }

      try {
        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );

        const itemDownload = response.data.linkDownloadImagensObs.filter(
          (item) => {
            if (item.nrSql === nrSqlImage) {
              return item;
            }
          }
        );
        loading.dismiss();
        window.open(itemDownload[0].linkDownload, "_blank");
      } catch (error) {
        loading.dismiss();
        this.openModal(
          "Erro ao buscar imagem",
          `${error.response.data.code} - ${error.response.data.message}`,
          "danger"
        );
      }
    },
  },
};
</script>

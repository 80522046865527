import Repository from "../Repository";
import {
  UserNewPasswordRequest,
  UserUploadAvatarRequest,
  UserUploadAvatarResponse,
} from "./UserInterfaces";

const resourceSingle = "/usuario";
//const resource = "/usuarios";

export default {
  obterUsuarioLogado<UserLoggedResponse>() {
    return Repository.get<UserLoggedResponse>(`${resourceSingle}_logado`);
  },
  lostPassword<UserNewPasswordResponse>(payload: UserNewPasswordRequest) {
    return Repository.post<UserNewPasswordResponse>(`lost_password`, payload);
  },
};

export const obterLinkUploadAvatar = (
  codigo: number,
  request: UserUploadAvatarRequest
) => {
  return Repository.put<UserUploadAvatarResponse>(
    `${resourceSingle}/link_upload_avatar/${codigo}`,
    request
  );
};

import Repository from "./Repository";

const resource = "/pedido_alinhadores";

export default {
  criarPedidoAlinhadores(body) {
    return Repository.post(`${resource}`, body);
  },
  alterarPedidoAlinhadores(codigo, body) {
    return Repository.put(`${resource}/${codigo}`, body);
  },
  buscarPedidoAlinhadores(nrPedido) {
    return Repository.get(`${resource}/${nrPedido}`);
  },
};

<template>
  <ion-page>
    <ion-content>
      <ion-grid v-if="dadosPlanejamento">
        <ion-item lines="none">
          <ion-label>
            <p>
              Número
            </p>
            <h2>
              {{ dadosPlanejamento.nrProjeto }}
            </h2>
          </ion-label>
          <ion-label>
            <p>Data</p>
            <h2>
              {{
                new Date(
                  dadosPlanejamento.dataHoraProjeto.substring(0, 10)
                ).toLocaleDateString("pt-BR", { timeZone: "UTC" })
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Total Alin Superiores
            </p>
            <h2>
              {{
                dadosPlanejamento.qtdTotalAlinhadoresSuperior
                  ? dadosPlanejamento.qtdTotalAlinhadoresSuperior
                  : "N/A"
              }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Total Alin Inferiores
            </p>
            <h2>
              {{
                dadosPlanejamento.qtdTotalAlinhadoresInferior
                  ? dadosPlanejamento.qtdTotalAlinhadoresInferior
                  : "N/A"
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>Link Video</p>
             <ion-button
                shape="round"
                size="small"
                color="danger"
                expand="block"
                :disabled="!dadosPlanejamento.linkHttpVideo"
                @click="openInBrowser(dadosPlanejamento.linkHttpVideo)"
              >
                Clique Aqui
              </ion-button>
          </ion-label>
        </ion-item>
        <ion-item v-if="dadosPlanejamento.textoRecusa">
          <ion-label class="ion-text-wrap">
            <p>Motivo Recusa</p>
            <h2>
              {{ dadosPlanejamento.textoRecusa }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            <p>Obs. Geral</p>
            <h2>
              {{
                dadosPlanejamento.observacaoProjeto
                  ? dadosPlanejamento.observacaoProjeto
                  : "Não informado"
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>
            observações
          </ion-label>
        </ion-item-divider>
        <ObservacoesPlanejamentoSolicitacao
          :listaObservacoes="dadosPlanejamento.listaImagens"
          :nrProjeto="dadosPlanejamento.nrProjeto"
        />
        <ion-item-divider>
          <ion-label>
            Arquivos
          </ion-label>
        </ion-item-divider>
        <ion-grid v-if="links">
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                color="dark"
                expand="block"
                :disabled="!links.linkDownloadArquivoProjeto"
                @click="openInBrowser(links.linkDownloadArquivoProjeto)"
              >
                Arquivo Projeto
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadArquivoSupInicial"
                @click="openInBrowser(links.linkDownloadArquivoSupInicial)"
              >
                Maxila Inicial
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="primary"
                :disabled="!links.linkDownloadArquivoSupFinal"
                @click="openInBrowser(links.linkDownloadArquivoSupFinal)"
              >
                Maxila Final
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkUploadArquivoInfInicial"
                @click="openInBrowser(links.linkUploadArquivoInfInicial)"
              >
                Mandíbula Inicial
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                shape="round"
                size="small"
                expand="block"
                color="tertiary"
                :disabled="!links.linkUploadArquivoInfFinal"
                @click="openInBrowser(links.linkUploadArquivoInfFinal)"
              >
                Mandíbula Final
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonItem,
  IonLabel,
  IonItemDivider,
  IonButton,
  loadingController,
} from "@ionic/vue";

import { Browser } from "@capacitor/browser";

import { cubeOutline } from "ionicons/icons";

import projetoArquivosRepositoryV2 from "@/repository/projetoArquivosRepositoryV2";
import ObservacoesPlanejamentoSolicitacao from "@/views/solicitacao-projeto/consulta-solicitacao/dados-projetos-solicitacao/ObservacoesPlanejamentoSolicitacao";

export default {
  name: "ModalConsultaPlanejamento",
  props: {
    nrPlanejemaneto: String,
    dadosPlanejamentoProps: Object,
  },
  setup() {

    const openInBrowser = async (url) => {
      console.log("url", url);
      await Browser.open({ url: url });
    };

    return { openInBrowser };
  },
  data() {
    return {
      dadosPlanejamento: null,
      links: null,
      cubeOutline: cubeOutline,
    };
  },
  methods: {
    async gerarLinksArquivos() {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      loadingIonic.present();
      try {
        const body = {
          projeto: this.dadosPlanejamento["@id"],
          operacao: "D",
        };
        const response = await projetoArquivosRepositoryV2.gerarLinkArquivos(
          body
        );
        this.links = response.data;
        loadingIonic.dismiss();
      } catch (e) {
        loadingIonic.dismiss();
      }
    },
  },
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonItemDivider,
    IonButton,
    ObservacoesPlanejamentoSolicitacao,
  },
  beforeMount() {
    this.dadosPlanejamento = this.dadosPlanejamentoProps;
    this.gerarLinksArquivos();
  },
};
</script>

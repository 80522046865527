<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="end" @click="navigate('Perfil')">
          <ion-icon size="large" :icon="closeOutline"></ion-icon>
        </ion-buttons>
        <ion-title>
          Imagem Perfil
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <ion-img
              class="blueal-avatar"
              :src="imageUrlRef"
              @ionError="
                setImageUrlRef(anonUser);
                setImgTakedRef(false);
              "
            ></ion-img>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="tertiary"
              strong
              shape="round"
              @click="takePicture()"
            >
              <ion-icon :icon="camera" />Alterar
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              :disabled="!imgTakedRef"
              strong
              shape="round"
              @click="getLinkUploadAvatar()"
            >
              <ion-icon :icon="save" />Salvar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  loadingController,
  modalController,
} from "@ionic/vue";

import { closeOutline, camera, save } from "ionicons/icons";

import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import { Camera, CameraResultType } from "@capacitor/camera";

import mime from "mime-types";

import BluealModalAlert from "@/components/bluealmodal/BluealModalAlert.vue";

import store from "@/storage/storage";
import { obterLinkUploadAvatar } from "@/repository/user/UserRepository";
import { UserUploadAvatarRequest } from "@/repository/user/UserInterfaces";

//const imageAnon = require("@/assets/anonymous-user.png");
import anonUser from "@/assets/anonymous-user.png";

const config = defineComponent({
  name: "ImagemPerfil",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
  },
  setup() {
    const codigoUserRef = ref<number>();
    const setCodigoUserRef = (state: number) => (codigoUserRef.value = state);

    const imageUrlRef = ref<string>();
    const setImageUrlRef = (state: string) => (imageUrlRef.value = state);

    const imgTakedRef = ref<boolean>(false);
    const setImgTakedRef = (state: boolean) => (imgTakedRef.value = state);

    const router = useRouter();

    const navigate = (routeName: string) => {
      router.replace({ name: routeName });
    };

    const openModalError = async (
      title: string,
      message: string,
      color: string
    ) => {
      const modal = await modalController.create({
        component: BluealModalAlert,
        componentProps: {
          title: title,
          message: message,
          color: color,
          closeModal: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      if (image.webPath) {
        setImageUrlRef(image.webPath);
        setImgTakedRef(true);
      } else {
        setImgTakedRef(false);
      }
    };

    const uploadArquivo = async (
      arquivo: File,
      linkUpload: string,
      fileExt: string
    ) => {
      return new Promise((resolve, reject) => {
        const reqArquivo = new XMLHttpRequest();
        reqArquivo.open("PUT", linkUpload);
        reqArquivo.setRequestHeader("Content-Type", fileExt);
        reqArquivo.onload = () => {
          if (reqArquivo.status === 200) {
            resolve(reqArquivo.response);
          } else {
            reject({
              status: reqArquivo.status,
              statusText: reqArquivo.statusText,
            });
          }
        };
        reqArquivo.onerror = () => {
          reject({
            status: reqArquivo.status,
            statusText: reqArquivo.statusText,
          });
        };
        reqArquivo.send(arquivo);
      });
    };

    const getLinkUploadAvatar = async () => {
      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      try {
        loading.present();

        if (!imageUrlRef.value) {
          throw new Error("Imagem não definida.");
        }

        const imgBlob = await fetch(imageUrlRef.value).then((r) => r.blob());
        const extImg = mime.extension(imgBlob.type);
        const imgFile = new File([imgBlob], "avatar." + extImg);

        if (!extImg) {
          throw new Error("Não foi possível identificar a extensão da imagem");
        }

        const req: UserUploadAvatarRequest = {
          extAvatar: extImg,
        };

        if (!codigoUserRef.value) {
          throw new Error("Não foi possível identificar a extensão da imagem");
        }

        const response = await obterLinkUploadAvatar(codigoUserRef.value, req);

        loading.message = "Salvando...";
        await uploadArquivo(
          imgFile,
          response.data.imagemLinkUpload,
          imgBlob.type
        );
        loading.dismiss();
        navigate("Perfil");
      } catch (error) {
        loading.dismiss();
        openModalError("Erro", error.message, "danger");
      }
    };

    onBeforeMount(async () => {
      const user = await store.get("@user");
      setCodigoUserRef(user.codigoUsuario);
      setImageUrlRef(user.linkImagem);
    });

    return {
      imageUrlRef,
      setImageUrlRef,
      imgTakedRef,
      setImgTakedRef,
      anonUser,
      navigate,
      closeOutline,
      takePicture,
      camera,
      save,
      getLinkUploadAvatar,
    };
  },
});

export default config;
</script>

<style scoped>
.blueal-avatar::part(image) {
  border-radius: 50% !important;
  width: 70vw;
  height: 70vw;
  max-width: 600px;
  max-height: 600px;
  object-fit: scale-down;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>


import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButtons,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";

const bluealModal = defineComponent({
  name: "Modal",
  props: {
    title: { type: String, default: "Super Modal" },
    color: { type: String, default: "primary" },
    message: { type: String, default: "Mensagem" },
    closeModal: { type: Function}
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);
    return { isOpenRef, setOpen, closeCircleOutline };
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButtons,
  },
});

export default bluealModal;

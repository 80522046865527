import qs from "qs";
import Repository from "../Repository";
import {
  GetServiceListaParamsRequest,
  GetServiceListaResponse,
  GetServicoResponse,
} from "./ServicoData";

const resource = "/servicos";

export const buscaServicos = (params: GetServiceListaParamsRequest) => {
  return Repository.get<GetServiceListaResponse>(`${resource}`, {
    params: {
      ...params,
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });
};


import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonToggle,
  IonTextarea,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosAdiconaisTratamento",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonToggle,
    IonTextarea,
  },
  emits: ["dadosAdicionaisTratamentoAlteracao"],
  setup(props, { emit }) {
    const dentesNaoMovimentarRef = ref<string | null>(null);
    const possuiDentesNaoMovimentarRef = ref<boolean>(false);
    const setPossuiDentesNaoMovimentarRef = (state: boolean) =>
      (possuiDentesNaoMovimentarRef.value = state);

    const attachmentRef = ref<string | null>(null);
    const possuiAttachmentRef = ref<boolean>(false);
    const setPossuiAttachmentRef = (state: boolean) =>
      (possuiAttachmentRef.value = state);

    const tempoTratamentoRef = ref<string | null>(null);
    const possuiTempoTratamentoRef = ref<boolean>(false);
    const setPossuiTempoTratamentoRef = (state: boolean) =>
      (possuiTempoTratamentoRef.value = state);

    const infoAdicionaisRef = ref<string | null>(null);
    const possuiInfoAdicionaisRef = ref<boolean>(false);
    const setPossuiInfoAdicionaisRef = (state: boolean) =>
      (possuiInfoAdicionaisRef.value = state);

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        movimentoDentes: dentesNaoMovimentarRef.value,
        observacao: infoAdicionaisRef.value,
        tempoTratamento: tempoTratamentoRef.value,
        attachmentDentes: attachmentRef.value,
      };

      emit("dadosAdicionaisTratamentoAlteracao", dadosTratametno);
    };

    return {
      dentesNaoMovimentarRef,
      possuiDentesNaoMovimentarRef,
      setPossuiDentesNaoMovimentarRef,
      attachmentRef,
      possuiAttachmentRef,
      setPossuiAttachmentRef,
      tempoTratamentoRef,
      possuiTempoTratamentoRef,
      setPossuiTempoTratamentoRef,
      infoAdicionaisRef,
      possuiInfoAdicionaisRef,
      setPossuiInfoAdicionaisRef,
      emitirDadosTratamento,
    };
  },
});

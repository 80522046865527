import Repository from './Repository';

const resource = '/solicitacao_projeto_lista';

export default {
    listarSolicitacoes(page) {
        return Repository.get(
            `${resource}`,
            {
                params: {
                    page: page
                }
            }
        );
    },
    listarSolicitacoesFiltro(estado, page) {
        return Repository.get(
            `${resource}`,
            {
                params: {
                    codigoEstadoSolicitacao: estado,
                    page: page
                }
            }
        );
    }
}

<template>
  <ion-page>
    <ion-content>
      <ion-item-group v-if="!loading">
        <ion-item>
          <ion-label>
            <p>
              Nr. Pedido
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.nrPedido }}
            </h2>
          </ion-label>
          <ion-label>
            <p>Data</p>
            <h2>
              {{
                new Date(
                  dadosPedidoAlinhadores.dataPedido
                ).toLocaleDateString("pt-BR", { timeZone: "UTC" })
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Nr. Projeto
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.projeto.nrProjeto }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Est. Entrega
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.dataEstimadaEntrega }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Estado
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.estadoPedido.descricaoEstadoPedido }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Paciente
            </p>
            <h2>
              {{ nomePaciente }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>
              Valor Pedido
            </p>
            <h2>
              {{
                (
                  dadosPedidoAlinhadores.valorTotalPedido -
                  dadosPedidoAlinhadores.valorFrete
                ).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Valor Frete
            </p>
            <h2>
              {{
                dadosPedidoAlinhadores.valorFrete.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Valor Total
            </p>
            <h2>
              {{
                dadosPedidoAlinhadores.valorTotalPedido.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>Alinhadores Solicitados</ion-label>
        </ion-item-divider>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <ion-button
                size="small"
                shape="round"
                expand="block"
                color="tertiary"
                strong
                @click="
                  abrirListaAlinhadores(
                    $event,
                    dadosPedidoAlinhadores.listaAlinhadoresSuperior
                  )
                "
              >
                Superior&nbsp;
                <ion-badge color="light">
                  {{ dadosPedidoAlinhadores.qtdAlinhadoresSuperior }}
                </ion-badge>
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                size="small"
                shape="round"
                expand="block"
                color="tertiary"
                strong
                @click="
                  abrirListaAlinhadores(
                    $event,
                    dadosPedidoAlinhadores.listaAlinhadoresInferior
                  )
                "
              >
                Inferior&nbsp;
                <ion-badge color="light">
                  {{ dadosPedidoAlinhadores.qtdAlinhadoresInferior }}
                </ion-badge>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-item lines="none">
          <ion-label>
            <p>
              Guia Attachment Superior
            </p>
            <h2>
              {{
                dadosPedidoAlinhadores.guiaAttachPedido &&
                dadosPedidoAlinhadores.guiaAttachPedido.attachmentSuperior
                  ? "SIM"
                  : "NÃO"
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>
              Guia Attachment Inferior
            </p>
            <h2>
              {{
                dadosPedidoAlinhadores.guiaAttachPedido &&
                dadosPedidoAlinhadores.guiaAttachPedido.attachmentInferior
                  ? "SIM"
                  : "NÃO"
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>Endereço Entrega</ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>
            <p>
              Logradouro
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.logradouro }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Nr.
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.numero }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              Complemento
            </p>
            <h2>
              {{
                dadosPedidoAlinhadores.enderecoEntrega.complemento
                  ? dadosPedidoAlinhadores.enderecoEntrega.complemento
                  : "Não Informado"
              }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Bairro
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.bairro }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              Município
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.municipio }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>
              UF
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.uf }}
            </h2>
          </ion-label>
          <ion-label>
            <p>
              CEP
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.enderecoEntrega.cep }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <p>
              Tipo Frete
            </p>
            <h2>
              {{ dadosPedidoAlinhadores.freteTipo.descricao }}
            </h2>
          </ion-label>
        </ion-item>
        <ion-item-divider>
          <ion-label>Opções</ion-label>
        </ion-item-divider>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-button
                expand="block"
                strong
                shape="round"
                size="small"
                color="medium"
                @click="recuperarBoleto()"
              >
                <ion-icon :icon="barcodeOutline"></ion-icon>
                Boleto
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                v-if="dadosPedidoAlinhadores.codigoRastreio"
                expand="block"
                strong
                shape="round"
                size="small"
                color="primary"
                @click="rastrearPedido(dadosPedidoAlinhadores.codigoRastreio)"
              >
                <ion-icon :icon="globeOutline"></ion-icon>
                Rastrear
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item-group>
      <ion-item-group v-if="loading">
        <ion-item v-for="n in 10" :key="n">
          <ion-label>
            <ion-skeleton-text animated style="width: 80%; height: 2rem" />
          </ion-label>
        </ion-item>
      </ion-item-group>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonIcon,
  IonItemDivider,
  IonItemGroup,
  loadingController,
  IonButton,
  IonBadge,
  IonGrid,
  IonRow,
  IonCol,
  popoverController,
} from "@ionic/vue";

import { barcodeOutline, filterOutline, globeOutline } from "ionicons/icons";

import { Browser } from "@capacitor/browser";

import ListaAlinhadoresPedido from "@/views/pedidos-alinhadores-gerenciar/consulta-pedidos-alinhadores/ListaAlinhadoresPedido.vue";
import pedidoAlinhadoresRepositoryV2 from "@/repository/pedidoAlinhadoresRepositoryV2";
import cobrancaRepository from "@/repository/cobrancaRepository";

export default {
  name: "ModalConsultarPedidoAlinhadores",
  props: {
    nrPedidoAlinhadores: String,
    nomePaciente: String,
  },
  data() {
    return {
      dadosPedidoAlinhadores: null,
      loading: false,
      barcodeOutline: barcodeOutline,
      filterOutline: filterOutline,
      globeOutline: globeOutline,
    };
  },
  components: {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonIcon,
    IonItemDivider,
    IonItemGroup,
    IonButton,
    IonBadge,
    IonGrid,
    IonRow,
    IonCol,
  },
  beforeMount() {
    this.buscarPedidoAlinhadores(this.nrPedidoAlinhadores);
  },
  methods: {
    async buscarPedidoAlinhadores(nrPedido) {
      this.dadosPedidoAlinhadores = null;
      this.loading = true;
      try {
        const {
          data,
        } = await pedidoAlinhadoresRepositoryV2.buscarPedidoAlinhadores(
          nrPedido
        );
        this.loading = false;
        this.dadosPedidoAlinhadores = data;
      } catch (error) {
        this.loading = false;
        console.error("error", error);
      }
    },
    async abrirListaAlinhadores(ev, listaAlinhadores) {
      const popover = await popoverController.create({
        component: ListaAlinhadoresPedido,
        componentProps: {
          listaAlinhadores: listaAlinhadores,
        },
        event: ev,
        translucent: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async recuperarBoleto() {
      const loadingIonic = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });
      await loadingIonic.present();

      try {
        const response = await cobrancaRepository.buscarCobrancaCodigo(
          this.dadosPedidoAlinhadores.cobranca.codigo
        );
        loadingIonic.dismiss();
        if (response.data.linkBoleto !== null) {
          await Browser.open({ url: response.data.linkBoleto });
        } else {
          console.log("sem boleto");
        }
      } catch (error) {
        loadingIonic.dismiss();
        console.error("error", error);
      }
    },
    async rastrearPedido(codRastreio) {
      await Browser.open({
        url: `https://www.mdlivre.com.br/tracking/view/${codRastreio}`,
      });
    },
  },
};
</script>

<template>
  <ion-grid v-if="!loading && listaProjetos">
    <ion-list v-for="item in listaProjetos" :key="item.nrProjeto">
      <ion-item
        @click="
          dadosPlanejamentoSelected = item;
          setIsOpenModalPlanejamentoRef(true);
        "
      >
        <ion-label>
          <p>
            Número
          </p>
          <h3>
            {{ item.nrProjeto }}
          </h3>
        </ion-label>
        <ion-label>
          <p>Situação</p>
          <h3>
            {{ item.estadoProjeto.descricao }}
          </h3>
        </ion-label>
        <ion-button slot="end" color="primary" strong
          ><ion-icon :icon="arrowForwardCircleOutline"
        /></ion-button>
      </ion-item>
    </ion-list>
  </ion-grid>
  <ion-grid v-if="!loading && listaProjetos.lenght === 0">
    <ion-text color="danger">
      <h5>Solicitação sem projetos</h5>
    </ion-text>
  </ion-grid>
  <ion-grid v-if="loading">
    <ion-row v-for="n in 2" :key="n" class="ion-justify-content-center">
      <ion-col>
        <ion-item>
          <ion-label>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 5px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              style="width: 80%; height: 10px"
            ></ion-skeleton-text>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-modal
    :is-open="isOpenModalPlanejamentoRef"
    @onDidDismiss="setIsOpenModalPlanejamentoRef(false)"
  >
    <BluealModal
      title="Detalhes Planejamento"
      color="primary"
      :closeModal="setIsOpenModalPlanejamentoRef"
    >
      <template v-slot:content>
        <ModalConsultaPlanejamento
          :dados-planejamento-props="dadosPlanejamentoSelected"
        />
      </template>
    </BluealModal>
  </ion-modal>
</template>

<script>
import {
  IonGrid,
  IonList,
  IonText,
  IonItem,
  IonLabel,
  IonCol,
  IonRow,
  IonSkeletonText,
  IonModal,
  IonIcon,
} from "@ionic/vue";

import { arrowForwardCircleOutline } from "ionicons/icons";

import { ref } from "@vue/reactivity";

import projetoRepositoryV2 from "@/repository/projetoRepositoryV2";
import ModalConsultaPlanejamento from "./ModalConsultaPlanejamento.vue";
import BluealModal from "@/components/bluealmodal/BluealModal.vue";

export default {
  name: "PlanejamentosSolicitacao",
  props: {
    nrSlctProjeto: String,
  },
  emits: ["projetoPendente"],
  components: {
    IonGrid,
    IonList,
    IonText,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    IonSkeletonText,
    IonModal,
    BluealModal,
    ModalConsultaPlanejamento,
    IonIcon,
  },
  data() {
    return {
      listaProjetos: null,
      dadosPlanejamentoSelected: null,
      loading: false,
    };
  },
  setup() {
    const isOpenModalPlanejamentoRef = ref(false);

    const setIsOpenModalPlanejamentoRef = (state) =>
      (isOpenModalPlanejamentoRef.value = state);

    return {
      setIsOpenModalPlanejamentoRef,
      isOpenModalPlanejamentoRef,
      arrowForwardCircleOutline,
    };
  },
  beforeMount: function() {
    this.listarProjetos();
  },
  methods: {
    async listarProjetos() {
      this.loading = true;
      try {
        const params = {
          solicitacaoProjeto: this.nrSlctProjeto,
        };
        const response = await projetoRepositoryV2.buscaProjetoPorSolicitacao(
          params
        );
        this.listaProjetos = response.data["hydra:member"];
        const projetoPendente = this.listaProjetos.filter((item) => {
          return item.estadoProjeto.cdEstProjeto === 20;
        })[0];
        if (projetoPendente) {
          this.$emit("projetoPendente", projetoPendente.nrProjeto);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>


import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButtons,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

const bluealModal = defineComponent({
  name: "Modal",
  props: {
    title: { type: String, default: "Super Modal" },
    color: { type: String, default: "primary" },
    closeModal: { type: Function, required: false },
    routeOnClose: { type: String, required: false },
  },
  setup() {
    const navigation = useRouter();

    const navigateOnClose = (routeName: string) => {
      navigation.replace({ name: routeName });
    };

    return {
      navigateOnClose,
    };
  },
  data() {
    return {
      closeOutline: closeCircleOutline,
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButtons,
  },
});

export default bluealModal;


import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonListHeader,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DadosTratamento",
  components: {
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonListHeader,
    IonRadioGroup,
    IonRadio,
    IonInput,
    IonSegment,
    IonSegmentButton,
  },
  emits: ["dadosTratamentoAlteracao"],
  setup(props, { emit }) {
    const arcadasMovimentacaoRef = ref<string | null>(null);
    const relacaoMolar = ref<string | null>(null);
    const implantesRef = ref<string | null>(null);
    const desgasteInterProximal = ref<string | null>(null);

    const linhaMediaSuperiorDirecaoRef = ref<string | null>(null);
    const linhaMediaSuperiorDistanciaRef = ref<string | null>(null);
    const possuiLinhaMediaSuperiorRef = ref<boolean>(false);
    const setPossuiLinhaMediaSuperiorRef = (state: boolean) => {
      possuiLinhaMediaSuperiorRef.value = state;
      if (!state) {
        linhaMediaSuperiorDirecaoRef.value = null;
        linhaMediaSuperiorDistanciaRef.value = null;
      }
    };

    const linhaMediaInferiorDirecaoRef = ref<string | null>(null);
    const linhaMediaInferiorDistanciaRef = ref<string | null>(null);
    const possuiLinhaMediaInferiorRef = ref<boolean>(false);
    const setPossuiLinhaMediaInferiorRef = (state: boolean) => {
      possuiLinhaMediaInferiorRef.value = state;
      if (!state) {
        linhaMediaInferiorDirecaoRef.value = null;
        linhaMediaInferiorDistanciaRef.value = null;
      }
    };

    const emitirDadosTratamento = () => {
      const dadosTratametno = {
        arcadaMovimentacao: arcadasMovimentacaoRef.value,
        desgasteInterProximal: desgasteInterProximal.value,
        linhaSuperiorMedia: possuiLinhaMediaSuperiorRef.value
          ? "Corrigir"
          : "Manter",
        linhaSuperiorDirecao: linhaMediaSuperiorDirecaoRef.value,
        linhaSuperiorMediaMovimento: linhaMediaSuperiorDistanciaRef.value,
        linhaInferiorMedia: possuiLinhaMediaInferiorRef.value
          ? "Corrigir"
          : "Manter",
        linhaInferiorDirecao: linhaMediaInferiorDirecaoRef.value,
        linhaInferiorMovimento: linhaMediaInferiorDistanciaRef.value,
        relacaoMolar: relacaoMolar.value,
      };

      emit("dadosTratamentoAlteracao", dadosTratametno);
    };

    return {
      arcadasMovimentacaoRef,
      relacaoMolar,
      implantesRef,
      desgasteInterProximal,
      linhaMediaSuperiorDirecaoRef,
      linhaMediaSuperiorDistanciaRef,
      possuiLinhaMediaSuperiorRef,
      setPossuiLinhaMediaSuperiorRef,
      linhaMediaInferiorDirecaoRef,
      linhaMediaInferiorDistanciaRef,
      possuiLinhaMediaInferiorRef,
      setPossuiLinhaMediaInferiorRef,
      emitirDadosTratamento,
    };
  },
});

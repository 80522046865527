
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonButton,
  IonModal,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";

import ModalListaPaciente from "@/views/paciente/ModalListaPaciente.vue";
import ModalIncluirPaciente from "@/views/paciente/ModalIncluirPaciente.vue";

import { PacienteResponse } from "@/repository/Paciente/PacienteData";

export default defineComponent({
  name: "DadosPaciente",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonModal,
    BluealModal,
    ModalListaPaciente,
    ModalIncluirPaciente,
  },
  emits: ["pacienteAlterado"],
  setup(props, { emit }) {
    const isOpenModalPacientesRef = ref<boolean>();
    const setIsOpenModalPacientesRef = (state: boolean) =>
      (isOpenModalPacientesRef.value = state);

    const isOpenModalIncluirPacienteRef = ref<boolean>();
    const setIsOpenModalIncluirPacienteRef = (state: boolean) =>
      (isOpenModalIncluirPacienteRef.value = state);

    const pacienteRef = ref<PacienteResponse>();
    const setPacienteRef = (state: PacienteResponse) => {
      pacienteRef.value = state;
      emit('pacienteAlterado', pacienteRef.value);
    };

    return {
      pacienteRef,
      setPacienteRef,
      isOpenModalPacientesRef,
      setIsOpenModalPacientesRef,
      isOpenModalIncluirPacienteRef,
      setIsOpenModalIncluirPacienteRef,
      closeOutline,
    };
  },
});

import Repository from "./Repository";
import qs from "qs";

const resource = "/projeto";

export default {
  buscaProjeto(codigo) {
    return Repository.get(`${resource}/${codigo}`);
  },
  buscaProjetoPorSolicitacao(params) {
    return Repository.get(`${resource}`, {
      params: {
        ...params,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });
  },
  alterarProjeto(codigo, body) {
    return Repository.put(`${resource}/${codigo}`, body);
  },
};

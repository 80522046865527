import Repository from "../Repository";
import {
  PacienteResponse,
  IncluirPacienteRequest,
  ListaPacienteResponse,
} from "./PacienteData";

const resource = "/pacientes";
const resourceSingle = "/paciente";

export const buscaPacientes = () => {
  return Repository.get<ListaPacienteResponse>(`${resource}`);
};

export const incluirPaciente = (dadosPacienteReq: IncluirPacienteRequest) => {
  return Repository.post<PacienteResponse>(
    `${resourceSingle}`,
    dadosPacienteReq
  );
};

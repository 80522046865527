<template>
  <ion-list>
    <ion-item-divider color="light">
      <ion-label>
        Paciente
      </ion-label>
      <ion-button
        v-if="pacienteRef"
        strong
        shape="round"
        slot="end"
        @click="setPacienteRef(null)"
      >
        Alterar Paciente
      </ion-button>
    </ion-item-divider>
    <ion-item-group v-if="pacienteRef">
      <ion-item lines="none">
        <ion-label>
          <p>
            Paciente
          </p>
          <h2>
            {{ pacienteRef.nome }}
          </h2>
        </ion-label>
        <ion-label>
          <p>
            Dt. Nasci.
          </p>
          <h2>
            {{
              pacienteRef.dataNascimento
                ? new Date(pacienteRef.dataNascimento).toLocaleDateString(
                    "pt-BR",
                    {
                      timeZone: "UTC",
                    }
                  )
                : ""
            }}
          </h2>
        </ion-label>
      </ion-item>
    </ion-item-group>
    <ion-item lines="none" v-if="!pacienteRef">
      <ion-button
        strong
        shape="round"
        slot="start"
        @click="setIsOpenModalPacientesRef(true)"
      >
        Selecionar Paciente
      </ion-button>
      <ion-button
        color="tertiary"
        strong
        shape="round"
        slot="start"
        @click="setIsOpenModalIncluirPacienteRef(true)"
      >
        Incluir Paciente
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-modal
    :is-open="isOpenModalPacientesRef"
    @onDidDismiss="setIsOpenModalPacientesRef(false)"
  >
    <BluealModal
      title="Pacientes"
      color="primary"
      :closeModal="setIsOpenModalPacientesRef"
    >
      <template v-slot:content>
        <ModalListaPaciente
          @pacienteSelecionado="setPacienteRef($event)"
          @fecharModal="setIsOpenModalPacientesRef(false)"
        />
      </template>
    </BluealModal>
  </ion-modal>
  <ion-modal
    :is-open="isOpenModalIncluirPacienteRef"
    @onDidDismiss="setIsOpenModalIncluirPacienteRef(false)"
  >
    <BluealModal
      title="Pacientes"
      color="primary"
      :closeModal="setIsOpenModalIncluirPacienteRef"
    >
      <template v-slot:content>
        <ModalIncluirPaciente
          @pacienteSelecionado="setPacienteRef($event)"
          @fecharModal="setIsOpenModalIncluirPacienteRef(false)"
        />
      </template>
    </BluealModal>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonList,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonButton,
  IonModal,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";

import ModalListaPaciente from "@/views/paciente/ModalListaPaciente.vue";
import ModalIncluirPaciente from "@/views/paciente/ModalIncluirPaciente.vue";

import { PacienteResponse } from "@/repository/Paciente/PacienteData";

export default defineComponent({
  name: "DadosPaciente",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonModal,
    BluealModal,
    ModalListaPaciente,
    ModalIncluirPaciente,
  },
  emits: ["pacienteAlterado"],
  setup(props, { emit }) {
    const isOpenModalPacientesRef = ref<boolean>();
    const setIsOpenModalPacientesRef = (state: boolean) =>
      (isOpenModalPacientesRef.value = state);

    const isOpenModalIncluirPacienteRef = ref<boolean>();
    const setIsOpenModalIncluirPacienteRef = (state: boolean) =>
      (isOpenModalIncluirPacienteRef.value = state);

    const pacienteRef = ref<PacienteResponse>();
    const setPacienteRef = (state: PacienteResponse) => {
      pacienteRef.value = state;
      emit('pacienteAlterado', pacienteRef.value);
    };

    return {
      pacienteRef,
      setPacienteRef,
      isOpenModalPacientesRef,
      setIsOpenModalPacientesRef,
      isOpenModalIncluirPacienteRef,
      setIsOpenModalIncluirPacienteRef,
      closeOutline,
    };
  },
});
</script>

<template>
  <ion-page>
    <ion-content class="ion-padding">
      <form @submit.prevent="login()">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Email</ion-label>
                <ion-input
                  required
                  type="email"
                  v-model="email"
                  minlength="5"
                  maxlength="255"
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Senha</ion-label>
                <ion-input required type="password" v-model="password" />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center ion-margin-top">
            <ion-col size="10">
              <ion-button
                expand="block"
                strong
                shape="round"
                color="primary"
                type="submit"
              >
                <ion-icon slot="start" :icon="logIn"></ion-icon>
                Entrar
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="10">
              <ion-button
                expand="block"
                strong
                shape="round"
                color="danger"
                @click.prevent="setOpenModalLostPassword(true)"
              >
                <ion-icon slot="start" :icon="lockOpen" />
                Recuperar senha
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <ion-modal
        :is-open="isOpenodalLostPasswordRef"
        @onDidDismiss="
          setOpenModalLostPassword(false);
          setIsRedefinedPassword(false);
        "
      >
        <BluealModal
          title="Recuperar Senha"
          color="primary"
          :closeModal="setOpenModalLostPassword"
        >
          <template v-slot:content>
            <ModalLostPassword />
          </template>
        </BluealModal>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import axios from "axios";

import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon,
  IonModal,
  loadingController,
  toastController,
} from "@ionic/vue";

import { logIn, lockOpen } from "ionicons/icons";

import BluealModal from "@/components/bluealmodal/BluealModal.vue";
import ModalLostPassword from "@/views/login/ModalLostPassword.vue";

import {
  getUserLogged,
  setSessionToken,
} from "@/service/storage/SessionStorageService";

const menu = defineComponent({
  setup() {
    const router = useRouter();

    const email = ref<string>("");
    const password = ref<string>("");
    const isOpenodalLostPasswordRef = ref(false);

    const setOpenModalLostPassword = (state: boolean) =>
      (isOpenodalLostPasswordRef.value = state);

    const login = async () => {
      const payload = {
        email: email.value,
        password: password.value,
      };

      const loading = await loadingController.create({
        message: "Aguarde...",
        translucent: true,
        backdropDismiss: false,
      });

      try {
        loading.present();
        const response = await axios.post(
          `${process.env.VUE_APP_BLUE_ALIGNER_API}/authentication_token`,
          payload,
          {
            withCredentials: true,
          }
        );

        if (!response.data && !response.data.token) {
          throw new Error("Erro ao gerar token de sessão.");
        } else {
          await setSessionToken(response.data.token);
        }

        await getUserLogged();

        router.replace({ name: "Home" });

        loading.dismiss();
      } catch (error) {
        loading.dismiss();
        const toast = await toastController.create({
          color: "danger",
          message: error.message,
          duration: 2000,
        });
        toast.present();
        router.push({ name: "Login" });
      }
    };

    return {
      email,
      password,
      login,
      logIn,
      lockOpen,
      isOpenodalLostPasswordRef,
      setOpenModalLostPassword,
    };
  },
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
    BluealModal,
    IonModal,
    ModalLostPassword,
  },
});

export default menu;
</script>

<style scoped>
.user-avatar-login {
  font-size: 7rem;
}
</style>

import axios, { AxiosInstance } from "axios";
import router from "../router/index";
import { getSessionToken } from "@/service/storage/SessionStorageService";

//const baseDomain = "/";
const baseURL = `${process.env.VUE_APP_BLUE_ALIGNER_API}/`;

const client: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

client.interceptors.request.use(
  async (config) => {
    const tokenSession = await getSessionToken();
    if (tokenSession) {
      config.headers.Authorization = `Bearer ${tokenSession}`;
    } else {
      router.push({ name: "Login" });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    console.error(error);
    if (error.response.status === 401) {
      router.push({ name: "Login" });
    } else {
      return Promise.reject(error);
    }
  }
);

export default client;
